import * as React from "react";
import { Card, Table, Button, Divider, Input, Select } from "antd";
import { bookStore } from "./store";
import { observer } from "mobx-react";
import UpdateBookModal from "./components/update-book";
import { translate, InjectedTranslateProps } from "react-i18next";
import { createColumn } from "./columns";
import CreateBooksModal from "./components/createBooksModal";
import CreateGradeModal from "./components/createGradeModal";

@observer
class BookPage extends React.Component<InjectedTranslateProps> {
  componentWillMount() {
    bookStore.initData();
  }

  render() {
    let t = this.props.t;
    let statusList = [
      {
        label: t("statusFinished"),
        value: 1
      },
      {
        label: t("statusUnfinished"),
        value: 0
      }
    ];

    let authorizedSelectList = [
      {
        label: t("authorized"),
        value: 1
      },
      {
        label: t("unauthorized"),
        value: 0
      }
    ];
    return (
      <div>
        <Card>
          <Select
            style={{ width: 160 }}
            value={bookStore.queryCondition.status}
            onChange={e => {
              bookStore.queryCondition.status = e;
              bookStore.loadBooks();
            }}
          >
            {statusList.map(item => (
              <Select.Option value={item.value} key={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
          <Divider type="vertical" />
          <Select
            style={{ width: 160 }}
            value={bookStore.queryCondition.isCopyrightAuthorized}
            onChange={e => {
              bookStore.queryCondition.isCopyrightAuthorized = e;
              bookStore.loadBooks();
            }}
          >
            {authorizedSelectList.map(item => (
              <Select.Option value={item.value} key={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
          <Divider type="vertical" />
          <Input
            style={{ width: 160 }}
            placeholder={t("bookName")}
            value={bookStore.queryCondition.name}
            onChange={e => {
              bookStore.queryCondition.name = e.target.value;
            }}
          />
          <Divider type="vertical" />
          <Input
            style={{ width: 160 }}
            placeholder={t("author")}
            value={bookStore.queryCondition.author}
            onChange={e => (bookStore.queryCondition.author = e.target.value)}
          />
          <Divider type="vertical" />
          <Button
            type="primary"
            onClick={() => {
              bookStore.queryCondition.page = 1;
              bookStore.loadBooks();
            }}
          >
            {this.props.t("search")}
          </Button>
          <Divider type="vertical" />
          <Button
            type="primary"
            onClick={() => {
              bookStore.createBooksModal.visible = true;
            }}
          >
            {t("uploadBooks")}
          </Button>
          <Divider type="vertical" />
          <Button type="primary" onClick={() => bookStore.exportBooks()}>
            批量导出
          </Button>
          <Divider type="vertical" />
          <Button
            type="primary"
            onClick={() => (bookStore.createGradeModal.visible = true)}
          >
            批量补全成人评级
          </Button>
        </Card>
        {this.renderTable()}
        <CreateBooksModal />
        <CreateGradeModal />
        <UpdateBookModal
          {...bookStore.updateModal}
          adultGrade={bookStore.adultGrade}
          copyrights={bookStore.copyrights}
          isSafe={bookStore.isSafe}
          onCancel={() => {
            bookStore.updateModal.visible = false;
          }}
          onUpdate={(params: any) => {
            bookStore.updateBook(params);
          }}
        />
      </div>
    );
  }

  renderTable() {
    return (
      <Table
        dataSource={bookStore.books.slice()}
        columns={createColumn(this.props)}
        rowKey="id"
        scroll={{ x: "max-content" }}
        loading={bookStore.loading}
        pagination={{
          total: bookStore.total,
          current: bookStore.queryCondition.page,
          pageSize: bookStore.queryCondition.pageSize,
          showSizeChanger: true,
          showQuickJumper: true
        }}
        onChange={(
          { total, current, pageSize },
          filters,
          { columnKey, order }
        ) => {
          bookStore.queryCondition.page = current;
          bookStore.queryCondition.pageSize = pageSize;
          console.log("filters", filters);
          if (filters && filters.copyrightId) {
            bookStore.queryCondition.copyrightIds = filters.copyrightId.join(
              ","
            );
          }
          if (filters && filters.zhGenres) {
            bookStore.queryCondition.zhGeners = filters.zhGenres.join(",");
          }

          if (filters && filters.adultGrade) {
            console.log("---", filters.adultGrade);
            if (filters.adultGrade.length > 0) {
              bookStore.queryCondition.adultStatus = +filters.adultGrade[0];
            } else {
              delete bookStore.queryCondition.adultStatus;
            }
          }
          if (columnKey && order) {
            bookStore.queryCondition.orderByColumnKey = columnKey;
            bookStore.queryCondition.order =
              order === "descend" ? "desc" : "asc";
          }
          bookStore.loadBooks();
        }}
      />
    );
  }
}

export default translate("book")(BookPage);
