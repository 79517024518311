export enum PayPeriodEnum {
  month = "month", // 月
  quarter = "quarter", // 季度
  halfYear = "halYear", // 半年
  year = "year" // 年度
}
export interface Copyright {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  gainsharingRate: string;
  guaranteedAmount: string;
  name: string; // 名称
  website: string; // 官网
  apiUrl: string; // 接口地址
  contractNo: string; // 合同编号
  invoiceTitle: string; // 发票抬头
  taxpayerId: string; // 纳税人识别号
  bankAccount: string; // 银行账户
  bank: string; // 开户行
  payPeriod: PayPeriodEnum; // 结算周期
  registerAddress: string; // 注册地址
  fixedTelephone: string; // 固定电话
  address: string; //地址
  contact: string; // 联系人
  email: string; // 邮箱
  telephone: string; // 电话
  companyName?: string;
  enCompanyName?: string;
}

export interface CreateCopyrightController {
  canonicalName: string;
  id: number;
  name: string;
}

export interface UpdateCopyrightController {
  canonicalName: string;
  name: string;
}
