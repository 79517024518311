import "core-js";

import * as React from "react";
import * as ReactDOM from "react-dom";
import Dashboard from "app/pages/layout";
import { Router } from "@reach/router";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { message } from "antd";
import "normalize.css";
import "antd/dist/antd.css";
import { LoginPage } from "app/pages/login";
import { LOGIN } from "config/path.config";

import moment = require("moment");
import "moment/locale/zh-cn";
moment.locale("zh-cn");

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <Dashboard default={true} />
      <LoginPage path={LOGIN} />
    </Router>
  </I18nextProvider>,
  document.getElementById("root")
);
message.config({
  top: 100,
  duration: 2,
  maxCount: 1
});

if (module.hot) {
  module.hot.accept();
}
