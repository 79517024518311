import * as React from "react";
import {
  Card,
  Table,
  Button,
  Input,
  Form,
  Divider,
  Modal,
  message
} from "antd";
import { userManageStore } from "./store";
import { observer } from "mobx-react";
import { translate, InjectedTranslateProps } from "react-i18next";
import i18next = require("i18next");
import AddUserPage from "./components/addUser";
import UpdateUserPage from "./components/updateUser";

@observer
class UserManagePage extends React.Component<InjectedTranslateProps> {
  baseColumns = [
    {
      title: i18next.t("userManage:userName"),
      dataIndex: "user_name",
      key: "user_name"
    },
    {
      title: i18next.t("userManage:remark"),
      dataIndex: "remark",
      key: "remark"
    },
    {
      title: i18next.t("userManage:cp"),
      dataIndex: "cpName",
      key: "cpName"
    },
    {
      title: i18next.t("userManage:creatTime"),
      dataIndex: "create_time",
      key: "create_time"
    },
    {
      title: i18next.t("userManage:updateTime"),
      dataIndex: "update_time",
      key: "update_time"
    },
    {
      title: i18next.t("userManage:status"),
      dataIndex: "disabled",
      key: "disabled",
      render: (status: boolean) => (status == false ? "正常" : "被禁用")
    },
    {
      title: i18next.t("common:action"),
      dataIndex: "setting",
      key: "setting",
      render: (text: string, record: any) =>
        !record.disabled ? (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              type={"primary"}
              onClick={() => {
                userManageStore.isUpdate = true;
                userManageStore.updateUserParamms = record;
                // console.log("record", record);
              }}
            >
              设置
            </Button>
            <Button
              type={"danger"}
              onClick={() => userManageStore.delectPower(record.id)}
            >
              删除权限
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type={"danger"}
              onClick={() => userManageStore.delectPower(record.id)}
              disabled
            >
              恢复权限
            </Button>
          </div>
        )
    }
  ];

  componentDidMount() {
    userManageStore.initData();
  }

  submitUserInfo = async () => {
    try {
      const params = userManageStore.addUserParams;
      if (!params.user_name) {
        throw new Error("请填写用户名");
      }
      if (!params.password) {
        throw new Error("请填写用密码");
      }
      const pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
      if (!pattern.test(params.password)) {
        throw new Error("请填写8-16位密码，包含数字,字母");
      }
      await userManageStore.createUser();
      message.success({
        content: "添加成功"
      });
      userManageStore.addUserParams = {
        user_name: "",
        password: "",
        remark: "",
        cp: ""
      };
    } catch (error) {
      console.log(error);
      message.error(error.message || error);
    }
  };

  updateUserInfo = async () => {
    try {
      const params = userManageStore.updateUserParamms;
      if (!params.user_name) {
        throw new Error("请填写用户名");
      }
      // console.log("updateUserParamms", params);
      await userManageStore.updateUser();
      message.success({
        content: "修改成功"
      });
    } catch (error) {
      console.log(error);
      message.error(error.message || error);
    }
  };

  onChange = (
    type: "user_name" | "password" | "remark" | "cp",
    value: string
  ) => {
    userManageStore.addUserParams[type] = value;
  };

  onCancleAdd = () => {
    userManageStore.isAdd = false;
    userManageStore.addUserParams = {
      user_name: "",
      password: "",
      remark: "",
      cp: ""
    };
  };

  render() {
    return (
      <div>
        {this.renderSearch()}
        {this.renderTable()}
        {this.renderAddUser()}
        {this.renderUpdateUser()}
      </div>
    );
  }

  renderAddUser = () => {
    // console.log("renderAddUser", userManageStore.isAdd);
    return (
      <Modal
        visible={userManageStore.isAdd}
        footer={null}
        onCancel={() => this.onCancleAdd()}
        maskClosable={false}
      >
        <AddUserPage
          cpList={userManageStore.cpList}
          submit={this.submitUserInfo}
          onChangeInfo={this.onChange}
          addUserParams={userManageStore.addUserParams}
          onCancle={this.onCancleAdd}
        />
      </Modal>
    );
  };

  renderTable() {
    return (
      <Table
        dataSource={[...userManageStore.userInfoList]}
        columns={this.baseColumns as any}
        loading={userManageStore.loading}
        rowKey="id"
        pagination={{
          total: userManageStore.total,
          current: userManageStore.queryCondition.page,
          pageSize: userManageStore.queryCondition.pageSize,
          showSizeChanger: true,
          showQuickJumper: true
        }}
        onChange={(
          { total, current, pageSize },
          filters,
          { columnKey, order }
        ) => {
          userManageStore.queryCondition = {
            ...userManageStore.queryCondition,
            pageSize: pageSize,
            page: current
          };
          userManageStore.loadUserManageInfo();
        }}
      />
    );
  }

  renderSearch() {
    let t = this.props.t;
    return (
      <Card>
        <Form layout="inline">
          <Form.Item label={t("userManage:userName")}>
            <Input
              value={userManageStore.queryCondition.name}
              placeholder={t("common:pleaseInput")}
              onChange={(eve: any) => {
                userManageStore.queryCondition = {
                  ...userManageStore.queryCondition,
                  name: eve.target.value
                };
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                userManageStore.searchData();
              }}
            >
              {t("common:search")}
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              onClick={() => {
                userManageStore.resetData();
              }}
            >
              {t("common:reset")}
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              onClick={() => {
                userManageStore.isAdd = true;
                // console.log("222", userManageStore.isAdd);
              }}
            >
              {t("common:add")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }

  changeUserInfo = (type: "remark" | "user_name" | "cp", data: string) => {
    userManageStore.updateUserParamms[type] = data;
  };

  renderUpdateUser = () => {
    return (
      <Modal
        visible={userManageStore.isUpdate}
        footer={null}
        onCancel={() => (userManageStore.isUpdate = false)}
        maskClosable={false}
      >
        <UpdateUserPage
          cpList={userManageStore.cpList}
          submit={this.updateUserInfo}
          onChangeInfo={this.changeUserInfo}
          updateUserParams={userManageStore.updateUserParamms}
          onCancle={() => (userManageStore.isUpdate = false)}
        />
      </Modal>
    );
  };
}

export default translate("dayilRunningWater")(UserManagePage);
