import { PagingBaseParams } from "./Base";

export interface CreateBook {
  name: string;
  copyrightId?: number;
  copyrightName: string;
  author: string;
  authorizationStartAt: string;
  authorizationEndAt: string;
  authorizationChannel: string;
  grade: string;
  sensitivityRating?: string;
  gainsharingRate: string | null;
  guaranteedAmount?: string | null;
  textLength: number;
  targetAudience: number;
  zhGenres?: string;
  isCompleted: number;
  authorizationLanguage: string;
  isMutualAuthorization: number;
}

export interface GetBooksParam extends PagingBaseParams {
  id?: number[];
  name?: string;
  copyrightId?: number;
  copyrightIds?: string; // 多个使用`,`隔开
  author?: string;
  isCompleted?: boolean;
  isCopyrightAuthorized?: number;
  startCreatedAt?: string;
  endCreatedAt?: string;
  genreId?: string;
  tagId?: string;
  responseType?: "id";
  keywords?: string[];
  orderByColumnKey?: string;
  order?: "asc" | "desc"; // default is desc
  status?: number; // 1:已补全 0:未补全
  zhGeners?: string;
  adultStatus?: number;
}

export interface UploadAdultGrade {
  bookName: string;
  author: string;
  safe: string;
  sexy: string;
  badHabit: string;
  violenceAndAbuse: string;
  zh: string;
  zq: string;
  qt: string;
  copyrightId: string;
  copyrightName: string;
  gradeRemark: string;
  adultGrade: string;
}

export interface SimpleBook {
  id?: number;
  name: string;
  canonicalName?: string;
  subtitle?: string;
  cover?: string;
  ossUri?: string;
  synopsis: string;
  author: string;
  latestChapterIndex?: number;
  isCompleted: number;
  isCopyrightAuthorized: boolean;
  authorizationStartAt?: string;
  authorizationEndAt?: string;
  grade?: string;
  gainsharingRate?: string;
  guaranteedAmount?: string;
  status?: number;
  textLength?: number;
  targetAudience?: number;
  authorizationChannel?: string;
  authorizationLanguage?: string;
  isMutualAuthorization?: string;
  zhGenres?: string;
  remark?: string;
  adultGrade?: string;
  gradeRemark?: string;
}

export interface Book extends SimpleBook {
  id: number;
  copyrightId: number;
  genre: { id: number; name: string }[];
  tags: { id: number; name: string }[];
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
  keywords: { id: number; name: string }[];
}

export interface BookAnnotation {
  bookId: number;
  list: Annotation[];
}

export interface Annotation {
  id: number;
  name: string;
}

export const adultGradeMap = [
  { value: "sexy", label: "色情" },
  { value: "badHabit", label: "恶劣癖好" },
  { value: "violenceAndAbuse", label: "血腥暴力与虐待" },
  { value: "zh", label: "宗教恐怖与仇恨" },
  { value: "zq", label: "种族文化仇恨歧视" },
  { value: "qt", label: "其他" }
];

// type AdultGradeItem = {
//   [key in '1' | '2' | '3']: string;
// };
export interface AdultGrade {
  [key: string]: any;
}
