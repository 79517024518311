import { PageParam } from "../models/PageParam";
import { SystemConfig } from "../models/SystemConfig";
import BaseService from "./base";
export interface SystemConfigCondition extends PageParam {
  name?: string;
}
export default class SystemConfigService extends BaseService {
  hostPrefix: string = "/cp/system-config";
  async getOne(): Promise<SystemConfig> {
    const res = await this.get(`${this.hostPrefix}`);
    return res.data.data;
  }
  async update(SystemConfig: Partial<SystemConfig>) {
    const res = await this.post(`${this.hostPrefix}`, SystemConfig);
    return res.data.data;
  }
}
