import * as React from "react";
import * as style from "./styles.scss";
import { Layout, Button, Dropdown, Menu } from "antd";
import { dashboardStore } from "./store";
import PageRouter from "app/pages/route";
import { observer, Provider } from "mobx-react";
import MenuLayout from "./components/menu";
import { translate } from "react-i18next";
import { userService } from "app/service";
import { urlParams } from "config/app.config";
const { Header, Content, Sider } = Layout;

@observer
class Dashboard extends React.Component<any> {
  componentWillMount() {
    if (!userService.isLoggedIn()) {
      window.location.href = "/login";
    }
  }

  componentDidMount() {
    dashboardStore.initData();
  }

  componentWillReceiveProps(nextProps: any) {
    if (dashboardStore.menus.length && this.props.uri !== nextProps.uri) {
      dashboardStore.updateFeatures();
    }
  }

  render() {
    const t = this.props.t;
    const crowMenu = (
      <Menu>
        <Menu.ItemGroup>
          <a
            href={urlParams.CROW_CLIENT}
            target="_blank"
            className={style.atags}
          >
            标注前台
          </a>
        </Menu.ItemGroup>
        <Menu.ItemGroup>
          <a
            href={urlParams.CROW_REVISE}
            target="_blank"
            className={style.atags}
          >
            修订前台
          </a>
        </Menu.ItemGroup>
      </Menu>
    );
    const cpMenu = (
      <Menu>
        <Menu.ItemGroup>
          <a href={urlParams.CP_CLIENT} target="_blank" className={style.atags}>
            CP结算平台
          </a>
        </Menu.ItemGroup>
      </Menu>
    );

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Header className={style["header"]}>
          <div className={style.logo}>{t("adminTitle")}</div>
          <div className={style.tips}>
            <div className={style.atip}>
              <a
                href={urlParams.TRANSLATIION}
                target="_blank"
                className={style.atags}
              >
                推文AI出版平台
              </a>
              <Dropdown overlay={cpMenu}>
                <a className={style.checked} href={"#"}>
                  版权管理后台
                </a>
              </Dropdown>
              <Dropdown overlay={crowMenu}>
                <a href={urlParams.CROW_ADMI} target="_blank">
                  众包管理后台
                </a>
              </Dropdown>
            </div>
            <Button
              ghost
              shape="circle"
              size="small"
              icon="logout"
              onClick={() => dashboardStore.logout()}
            />
          </div>
        </Header>
        <Layout>
          <Sider
            className={style["side-bar"]}
            collapsible
            collapsed={dashboardStore.collapsed}
            onCollapse={collapsed => dashboardStore.setMenuCollapsed(collapsed)}
            breakpoint="lg"
            collapsedWidth="5.7rem"
          >
            <MenuLayout menus={dashboardStore.menus} />
          </Sider>
          <div className={style["content-container"]}>
            <Layout className={style["content-wrapper"]}>
              <Content className={style.content}>
                <Provider features={dashboardStore.features}>
                  <PageRouter />
                </Provider>
              </Content>
            </Layout>
          </div>
        </Layout>
      </Layout>
    );
  }
}

export default translate("common")(Dashboard);
