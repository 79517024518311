import * as React from "react";
import { Card, Table, Button, Input, Divider, Form } from "antd";
import { dailySaleRunningWaterStore } from "./store";
import { observer } from "mobx-react";

// import UpdateBookModal from "./components/update-book";
import { translate, InjectedTranslateProps } from "react-i18next";
import i18next = require("i18next");

@observer
class SaleDetailPage extends React.Component<InjectedTranslateProps> {
  baseColumns = [
    {
      title: i18next.t("saleDetail:bookName"),
      dataIndex: "zhName",
      key: "zhName"
    },
    {
      title: i18next.t("saleDetail:englishBookName"),
      dataIndex: "enName",
      key: "enName"
    },
    {
      title: i18next.t("saleDetail:author"),
      dataIndex: "author",
      key: "author"
    },
    {
      title: i18next.t("saleDetail:copyrightOwner"),
      dataIndex: "cp",
      key: "cp"
      // sorter: true
    },
    {
      title: i18next.t("saleDetail:grade"),
      dataIndex: "grade",
      key: "grade"
      // sorter: true
    },
    {
      title: i18next.t("saleDetail:payingNumber"),
      dataIndex: "babelIncome.uniqueCustomer",
      key: "babelIncome.uniqueCustomer"
      // sorter: true
    },
    {
      title: i18next.t("saleDetail:totalFlow"),
      dataIndex: "babelIncome.totalBrickSales",
      key: "babelIncome.totalBrickSales"
      // sorter: true
    },
    {
      title: i18next.t("saleDetail:totalPaidChapter"),
      dataIndex: "babelIncome.totalPaidChapter",
      key: "babelIncome.totalPaidChapter"
      // sorter: true
    }
    // ,
    //  {
    //   title: i18next.t("saleDetail:divideStream"),
    //   dataIndex: "divideStream",
    //   key: "divideStream",
    //   sorter: true
    // }
  ];

  async componentWillMount() {
    await dailySaleRunningWaterStore.initData();
  }

  render() {
    return (
      <div>
        {this.renderSelectForm()}
        {this.renderTable()}
      </div>
    );
  }

  renderTable() {
    return (
      <Table
        dataSource={[...dailySaleRunningWaterStore.salesList]}
        columns={this.baseColumns as any}
        rowKey="enName"
        loading={dailySaleRunningWaterStore.loading}
        pagination={{
          total: dailySaleRunningWaterStore.total,
          current: dailySaleRunningWaterStore.queryCondition.page,
          pageSize: dailySaleRunningWaterStore.queryCondition.page_size,
          showSizeChanger: true,
          showQuickJumper: true
        }}
        onChange={(
          { total, current, pageSize },
          filters,
          { columnKey, order }
        ) => {
          dailySaleRunningWaterStore.queryCondition = {
            ...dailySaleRunningWaterStore.queryCondition,
            page_size: pageSize,
            page: current
          };
          // if (columnKey && order) {
          //   order === "descend" ? "desc" : "asc";
          //   dailySaleRunningWaterStore.queryCondition.order = order;
          //   dailySaleRunningWaterStore.initData();
          //   console.log('order', order, 'columnKey', columnKey)
          // }
          dailySaleRunningWaterStore.loadBooks();
        }}
      />
    );
  }

  renderSelectForm() {
    let t = this.props.t;
    return (
      <Card>
        <Form layout="inline">
          <Form.Item label={t("book:bookName")}>
            <Input
              style={{ width: 160 }}
              placeholder={i18next.t("book:bookName")}
              value={dailySaleRunningWaterStore.queryCondition.book_name}
              onChange={e => {
                dailySaleRunningWaterStore.queryCondition = {
                  ...dailySaleRunningWaterStore.queryCondition,
                  book_name: e.target.value
                };
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => dailySaleRunningWaterStore.initData()}
            >
              {i18next.t("common:search")}
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              onClick={() => {
                dailySaleRunningWaterStore.queryCondition.book_name = "";
              }}
            >
              {i18next.t("common:reset")}
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              onClick={() => dailySaleRunningWaterStore.exportExl()}
            >
              {i18next.t("common:export")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default translate("dayilRunningWater")(SaleDetailPage);
