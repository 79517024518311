import { observable, action } from "mobx";
import {
  GetBookRunningWaterParam,
  BookRunningWater
} from "app/models/RunningWater";
import { message } from "antd";
import moment = require("moment");
import i18next = require("i18next");
import { runningWaterService } from "app/service";
import { CPInfoList } from "app/models/RunningWater";
import * as XLSX from "xlsx";
// const data = require('./data.json')
const emptyQueryCondition = {
  page: 1,
  page_size: 10
};

class BookRunningWaterStore {
  @observable salesList: BookRunningWater[] = [];
  @observable total: number = 0;
  @observable loading: boolean = false;
  @observable queryCondition: GetBookRunningWaterParam = emptyQueryCondition;
  @observable cpList: CPInfoList[] = [];

  @action.bound
  async initData() {
    await this.getAllCPs();
    this.queryCondition = {
      ...this.queryCondition,
      from_date:
        this.getQueryString("from_date") || moment().format("YYYY-MM-DD"),
      to_date: this.getQueryString("to_date") || moment().format("YYYY-MM-DD")
    };
    await this.loadBooks();
  }

  @action.bound
  async loadBooks() {
    this.loading = true;
    try {
      const bookRunningWaterSource = await runningWaterService.getBookRunningWater(
        this.queryCondition
      );
      const { list, total } = bookRunningWaterSource;
      this.salesList = list;
      this.total = total;
    } catch (error) {
      message.error(error.message);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async getAllCPs() {
    try {
      const data = await runningWaterService.getAllCPs();
      console.log(data, "data");
      this.cpList = data.data.copyrightDetails;
    } catch (error) {
      message.error(error.message || "获取版权方出错");
    }
  }

  @action.bound
  async resetData() {
    const resetDate = {
      page: 1,
      pageSize: this.queryCondition.page_size || 10,
      from_date:
        this.getQueryString("from_date") || moment().format("YYYY-MM-DD"),
      to_date: this.getQueryString("to_date") || moment().format("YYYY-MM-DD")
    };
    this.queryCondition = resetDate;
    this.initData();
  }

  @action.bound
  async searchData() {
    await this.loadBooks();
  }

  @action.bound
  async exportExl() {
    try {
      const queryCondition = {
        ...this.queryCondition,
        for_export: true
      };
      // console.log("queryCondition----", this.queryCondition, queryCondition);
      const bookRunningWaterSource = await runningWaterService.getBookRunningWater(
        { ...queryCondition }
      );
      const { list } = bookRunningWaterSource;
      this.salesList = list;
      const sourceData = [
        [
          i18next.t("saleDetail:bookName"),
          i18next.t("saleDetail:englishBookName"),
          i18next.t("saleDetail:author"),
          i18next.t("saleDetail:copyrightOwner"),
          i18next.t("saleDetail:grade"),
          i18next.t("saleDetail:payingNumber"),
          i18next.t("saleDetail:totalPaidSections"),
          i18next.t("saleDetail:totalFlow"),
          i18next.t("saleDetail:divideStream")
        ]
      ];
      for (let i = 0; i < this.salesList.length; i++) {
        sourceData.push([
          this.salesList[i].zhName,
          this.salesList[i].enName,
          this.salesList[i].author,
          this.salesList[i].cp,
          this.salesList[i].grade,
          this.salesList[i].babelIncome.uniqueCustomer,
          this.salesList[i].babelIncome.totalPaidChapter,
          this.salesList[i].babelIncome.totalBrickSales,
          this.salesList[i].babelIncome.totalSharedRevenue
        ]);
      }
      const source = XLSX.utils.aoa_to_sheet(sourceData);
      const wb = XLSX.utils.book_new();
      var ws_name = "Sheet1";
      XLSX.utils.book_append_sheet(wb, source, ws_name);
      const fileName = moment().format("YYYY/MM/DD");
      XLSX.writeFile(wb, `小说站书流水${fileName}.xlsx`);
    } catch (error) {
      throw error.message || "下载错误";
    }
  }

  // queryString 库换掉
  getQueryString(name: string) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  }
}
export const bookRunningWaterStore = new BookRunningWaterStore();
