import { observer } from "mobx-react";
import {
  Card,
  Table,
  Input,
  Form,
  Modal,
  Divider,
  Col,
  Row,
  message,
  Radio
} from "antd";
import * as React from "react";
import { copyrightStore } from "./store";
import { translate } from "react-i18next";
import { FormSection } from "app/components/FormSection";
import Button from "antd/lib/button";
import { getPaginationConfig } from "../base";
import { ColumnProps } from "antd/lib/table";
import { Copyright, PayPeriodEnum } from "app/models/Copyright";
import { showError } from "app/utils";
import RadioGroup from "antd/lib/radio/group";
import { Decimal } from "decimal.js";

const pageStore = copyrightStore;
@observer
class CopyrightPage extends React.Component<{ t: any }> {
  componentWillMount() {
    pageStore.initData();
  }
  render() {
    return (
      <div>
        {this.renderSearch()}
        {this.renderDialog()}
        {this.renderTable()}
      </div>
    );
  }
  async handSubmit(values: any) {
    let t = this.props.t;
    let params = JSON.parse(JSON.stringify(values));
    params.gainsharingRate = new Decimal(params.gainsharingRate)
      .div(100)
      .toString();
    try {
      if (pageStore.entity) {
        await pageStore.updateCopyright(pageStore.entity.id, params);
      } else {
        await pageStore.createCopyright(params);
      }
      message.success(t("saveSuccess"));
    } catch (err) {
      showError(err);
    }
    pageStore.visibleDialog = false;
  }
  renderSearch() {
    let t = this.props.t;
    return (
      <Card>
        <FormSection.Form
          name="search"
          formProps={{ layout: "inline" }}
          onSubmit={(values: any) => pageStore.search(values)}
        >
          <FormSection.FormItem
            label={t("common:name")}
            name={"name"}
            options={{ initialValue: pageStore.condition.name }}
          >
            <Input placeholder={t("common:pleaseInput")} />
          </FormSection.FormItem>
          <Form.Item>
            <FormSection.ResetButton
              type="primary"
              onClick={(values: any) => pageStore.search(values)}
            >
              {t("common:reset")}
            </FormSection.ResetButton>
            <Divider type="vertical" />
            <Button type="primary" onClick={() => pageStore.showDialog()}>
              {t("common:add")}
            </Button>
          </Form.Item>
        </FormSection.Form>
      </Card>
    );
  }
  getPayPeriods() {
    let t = this.props.t;
    return [
      {
        key: PayPeriodEnum.month,
        value: t("month")
      },
      {
        key: PayPeriodEnum.quarter,
        value: t("quarter")
      },
      {
        key: PayPeriodEnum.halfYear,
        value: t("halfYear")
      },
      {
        key: PayPeriodEnum.year,
        value: t("year")
      }
    ];
  }
  renderDialog() {
    const t = this.props.t;
    const entity: Partial<Copyright> = pageStore.entity || {};
    const payPeriodList: {
      key: string;
      value: string;
    }[] = this.getPayPeriods();
    return (
      <Modal
        width={800}
        title={t("editTitle")}
        onCancel={() => (pageStore.visibleDialog = false)}
        visible={pageStore.visibleDialog}
        footer={null}
      >
        <FormSection.Form
          name="dialog"
          onSubmit={(values: any) => this.handSubmit(values)}
        >
          <Row>
            <Col span={14}>
              <Divider type="horizontal">{t("copyrightTitle")}</Divider>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("name")}
                name={"name"}
                options={{
                  initialValue: entity.name,
                  rules: [
                    {
                      required: true,
                      message: "Please input your phone number!"
                    }
                  ]
                }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("companyName")}
                name={"companyName"}
                options={{
                  initialValue: entity.companyName,
                  rules: [
                    {
                      required: true,
                      message: "Please input your companyName!"
                    }
                  ]
                }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("enCompanyName")}
                name={"enCompanyName"}
                options={{
                  initialValue: entity.enCompanyName
                }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("website")}
                name={"website"}
                options={{
                  initialValue: entity.website,
                  rules: [{ type: "url" }]
                }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("apiUrl")}
                name={"apiUrl"}
                options={{ initialValue: entity.apiUrl }}
              >
                <Input />
              </FormSection.FormItem>
            </Col>
            <Col span={10}>
              <Divider type="horizontal">{t("contactTitle")}</Divider>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("contact")}
                name={"contact"}
                options={{ initialValue: entity.contact }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("email")}
                name={"email"}
                options={{
                  initialValue: entity.email,
                  rules: [{ type: "email" }]
                }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("telephone")}
                name={"telephone"}
                options={{ initialValue: entity.telephone }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("address")}
                name={"address"}
                options={{ initialValue: entity.address }}
              >
                <Input.TextArea />
              </FormSection.FormItem>
            </Col>
          </Row>
          <Divider type="horizontal">{t("defaultTitle")}</Divider>
          <Row>
            <Col span={11}>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("gainsharingRate")}
                name={"gainsharingRate"}
                options={{
                  initialValue: parseFloat(entity.gainsharingRate || "0") * 100,
                  rules: [
                    {
                      required: true,
                      message: "Please input gainsharing Rate!"
                    }
                  ]
                }}
              >
                <Input />
              </FormSection.FormItem>
            </Col>
            <Col span={11}>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("guaranteedAmount")}
                name={"guaranteedAmount"}
                options={{
                  initialValue: entity.guaranteedAmount || "0.00",
                  rules: [
                    {
                      required: true,
                      message: "Please input guaranteed amount!"
                    }
                  ]
                }}
              >
                <Input />
              </FormSection.FormItem>
            </Col>
          </Row>
          <Divider type="horizontal">{t("settleTitle")}</Divider>
          <Row>
            <Col span={11}>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("contractNo")}
                name={"contractNo"}
                options={{ initialValue: entity.contractNo }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("invoiceTitle")}
                name={"invoiceTitle"}
                options={{ initialValue: entity.invoiceTitle }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("taxpayerId")}
                name={"taxpayerId"}
                options={{ initialValue: entity.taxpayerId }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("bankAccount")}
                name={"bankAccount"}
                options={{ initialValue: entity.bankAccount }}
              >
                <Input />
              </FormSection.FormItem>
            </Col>
            <Col span={13}>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("bank")}
                name={"bank"}
                options={{ initialValue: entity.bank }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("payPeriod")}
                name={"payPeriod"}
                options={{
                  initialValue: entity.payPeriod || PayPeriodEnum.quarter
                }}
              >
                <RadioGroup>
                  {payPeriodList.map(item => (
                    <Radio key={item.key} value={item.key}>
                      {item.value}
                    </Radio>
                  ))}
                </RadioGroup>
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("fixedTelephone")}
                name={"fixedTelephone"}
                options={{ initialValue: entity.fixedTelephone }}
              >
                <Input />
              </FormSection.FormItem>
              <FormSection.FormItem
                {...FormSection.longFormItemLayout}
                label={t("registerAddress")}
                name={"registerAddress"}
                options={{ initialValue: entity.registerAddress }}
              >
                <Input.TextArea />
              </FormSection.FormItem>
            </Col>
          </Row>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              loading={pageStore.loadingSave}
              htmlType="submit"
            >
              {t("common:submit")}
            </Button>
            <Divider type="vertical" />
            <FormSection.ResetButton type="primary">
              {t("common:reset")}
            </FormSection.ResetButton>
          </Form.Item>
        </FormSection.Form>
      </Modal>
    );
  }
  renderTable() {
    let t = this.props.t;
    let columns: ColumnProps<Copyright>[] = [
      {
        title: t("name"),
        dataIndex: "name",
        key: "name"
      },
      {
        title: t("common:action"),
        key: "action",
        render: (id: string, entity: Copyright) => (
          <Button type="link" onClick={() => pageStore.showDialog(entity)}>
            {t("common:detail")}
          </Button>
        )
      }
    ];

    return (
      <Table
        columns={columns}
        dataSource={pageStore.list.slice()}
        onChange={pagination => {
          pageStore.search({
            page: pagination.current,
            pageSize: pagination.pageSize
          });
        }}
        pagination={getPaginationConfig({
          total: pageStore.total,
          current: pageStore.condition.pageNumber,
          pageSize: pageStore.condition.pageSize
        })}
      />
    );
  }
}
export default translate("copyright")(CopyrightPage);
