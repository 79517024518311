import { CopyrightCondition } from "./../../service/copyright";
import { copyrightService } from "app/service";
import { Copyright } from "app/models/Copyright";
import { showError } from "app/utils";
import { observable, action } from "mobx";
class CopyrightStore {
  @observable condition: CopyrightCondition = { pageNumber: 1, pageSize: 20 };
  @observable loading: boolean = false;
  @observable loadingDetail: boolean = false;
  @observable loadingSave: boolean = false;
  @observable visibleDialog: boolean = false;
  @observable total: number = 0;
  @observable list: Copyright[] = [];
  @observable entity?: Copyright;
  initData() {
    this.search();
  }
  @action.bound
  async search(
    con: CopyrightCondition = {
      page: 1,
      pageSize: 10
    }
  ) {
    this.loading = true;
    if (con.name != undefined) this.condition.name = con.name.trim();
    this.condition.pageNumber = con.page;
    this.condition.pageSize = con.pageSize;
    try {
      const res = await copyrightService.query(this.condition);
      this.total = res.totalCount;
      this.list = res.copyrightDetails || [];
    } catch (err) {
      showError(err);
    }
    this.loading = false;
  }
  @action.bound
  async loadCopyright(id: number) {
    this.loadingDetail = true;
    try {
      this.entity = await copyrightService.getById(id);
    } catch (err) {
      showError(err);
    }
    this.loadingDetail = false;
  }
  @action.bound
  async createCopyright(params: Partial<Copyright>) {
    this.loadingSave = true;
    try {
      let res = (await copyrightService.create(params)) || {};
      let newParams = {
        canonicalName: params.companyName,
        id: res.id,
        name: params.name
      };
      await copyrightService.createCopyrightController(newParams);
      this.search();
      this.loadingSave = false;
    } catch (err) {
      this.loadingSave = false;
      throw err;
    }
  }
  @action.bound
  async updateCopyright(id: number, params: Partial<Copyright>) {
    this.loadingSave = true;
    try {
      await copyrightService.update(id, params);
      let newParams = {
        canonicalName: params.companyName,
        name: params.name
      };
      await copyrightService.updateCopyrightController(id, newParams);
      this.search(this.condition);
      this.loadingSave = false;
    } catch (err) {
      this.loadingSave = false;
      throw err;
    }
  }
  showDialog(entity?: Copyright) {
    this.entity = entity;
    this.visibleDialog = true;
  }
}
export const copyrightStore = new CopyrightStore();
