import BaseService from "./base";
import {
  BookRunningWater,
  GetBookRunningWaterParam,
  DailyRunningWater,
  SaleDetail,
  GetSalesDetailParam,
  CPBookRunningWater
} from "app/models/RunningWater";
import { PageList } from "app/models/PageList";

export default class RunningWaterService extends BaseService {
  // 获取小说站书流水
  public getBookRunningWater(
    params: GetBookRunningWaterParam
  ): Promise<PageList<BookRunningWater>> {
    const url = `/st/admin/income`;
    return this.get(url, params).then(res => res.data.data);
  }

  // 获取小说站日流水
  public getRunningWater(
    params: GetBookRunningWaterParam
  ): Promise<PageList<DailyRunningWater>> {
    return this.get("/st/admin/income/daily", params).then(
      res => res.data.data
    );
  }

  // 获取销售数据详情
  public getSaleDetail(
    params: GetSalesDetailParam
  ): Promise<PageList<SaleDetail>> {
    return this.get("/st/admin/income", params).then(res => res.data.data);
  }

  // 获取cp销售数据详情
  public getSaleCPDetail(
    params: GetBookRunningWaterParam
  ): Promise<PageList<CPBookRunningWater>> {
    return this.get("/st/cp/income", params).then(res => res.data.data);
  }

  // 获取所有版权
  public getAllCPs() {
    return this.get("/lunker/copyrights", {
      pageSize: 1000,
      page: 1
    }).then(res => res.data);
  }
}
