import * as React from "react";
import { Form as AntForm } from "antd";
import { FormComponentProps, FormItemProps } from "antd/lib/form";
import { inject, Provider } from "mobx-react";
import { GetFieldDecoratorOptions, FormProps } from "antd/lib/form/Form";
import Button from "antd/lib/button";
import { ButtonProps } from "antd/lib/button";

interface LocalProps {
  onSubmit: (values: any, e: React.FormEvent<HTMLFormElement>) => void;
}
export namespace FormSection {
  export class Form extends React.Component<
    LocalProps & { formProps?: FormProps; name?: string }
  > {
    render() {
      let WrapForm = AntForm.create(name)(FormSectionComponent);
      return <WrapForm {...this.props} />;
    }
  }

  //getFieldDecorator必须和Form.Item直接使用
  @inject("getFieldDecorator", "addField")
  export class FormItem extends React.Component<
    { options?: GetFieldDecoratorOptions; name: string } & FormItemProps
  > {
    constructor(props: any) {
      super(props);
      (this.props as any).addField(this.props.name);
    }
    render() {
      let props = { ...this.props };
      delete props.options;
      delete props.name;
      let getFieldDecorator = (this.props as any).getFieldDecorator;
      return (
        <AntForm.Item {...props}>
          {getFieldDecorator(`${this.props.name}`, this.props.options)(
            this.props.children
          )}
        </AntForm.Item>
      );
    }
  }

  @inject("clearFields")
  export class ResetButton extends React.Component<ButtonProps> {
    render() {
      let clearFields = (this.props as any).clearFields;
      let props: any = { ...this.props };
      delete props.form;
      delete props.clearFields;
      return (
        <Button
          {...props}
          onClick={e => {
            let values = { ...clearFields() };
            for (let key of Object.keys(values)) {
              values[key] = "";
            }
            this.props.onClick && this.props.onClick(values);
          }}
        >
          {this.props.children}
        </Button>
      );
    }
  }

  export const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    }
  };

  export const longFormItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 }
    }
  };

  export const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 12,
        offset: 4
      }
    }
  };
}

class FormSectionComponent extends React.Component<
  LocalProps & FormComponentProps & { formProps?: FormProps }
> {
  fields: any = {};
  addField = (name: string) => {
    this.fields[name] = undefined;
  };
  clearFields = () => {
    this.props.form.setFieldsValue(this.fields);
    return this.fields;
  };
  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      if (this.props.onSubmit) {
        this.props.onSubmit(values, e);
      }
    });
  };
  render() {
    return (
      <Provider
        getFieldDecorator={this.props.form.getFieldDecorator}
        addField={this.addField}
        clearFields={this.clearFields}
      >
        <AntForm {...this.props.formProps} onSubmit={this.onSubmit}>
          {this.props.children}
        </AntForm>
      </Provider>
    );
  }
}
