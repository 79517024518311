import { PaginationConfig } from "antd/lib/table";
import * as React from "react";

export function getPaginationConfig(pagination: PaginationConfig) {
  const defaultPagination: PaginationConfig = {
    current: 1,
    pageSize: 20,
    showTotal: total => <div>共{total}条</div>,
    showQuickJumper: true,
    showSizeChanger: true,
    ...pagination
  };
  return defaultPagination;
}
