import * as React from "react";
import { translate, InjectedTranslateProps } from "react-i18next";
import { Button, Icon, Modal, Table, Upload } from "antd";
import { observer } from "mobx-react";
import { bookStore } from "../store";
import { createBookColumn } from "../columns";

const CreateBooksModal = (props: InjectedTranslateProps) => {
  const TemplateUrl =
    "https://zh-novels-hk.oss-cn-hongkong.aliyuncs.com/static-data/%E7%89%88%E6%9D%83%E4%B9%A6%E7%9B%AE%E5%88%9B%E5%BB%BA%E6%A8%A1%E6%9D%BFv4.xlsx";

  const modal = bookStore.createBooksModal;
  let t = props.t;

  return (
    <Modal
      title={t("uploadBooks")}
      visible={modal.visible}
      width="90vw"
      onCancel={() => (modal.visible = false)}
      onOk={() => bookStore.addBooks()}
      confirmLoading={bookStore.addBooksLoading}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "1rem"
        }}
      >
        <Upload
          accept=".xls, .xlsx"
          multiple={false}
          fileList={[]}
          beforeUpload={file => {
            bookStore.parseExcelToData(file);
            return false;
          }}
        >
          <Button type="primary">{t("upload")}</Button>
        </Upload>

        <Button type="default" style={{ marginLeft: "10px" }}>
          <a download href={TemplateUrl}>
            <Icon type="download" />
            {t("download")}
          </a>
        </Button>
      </div>
      <Table
        scroll={{ x: "max-content" }}
        size="small"
        key={2}
        columns={createBookColumn(props) as any}
        dataSource={modal.books.slice()}
      />
    </Modal>
  );
};
export default translate("book")(observer(CreateBooksModal));
