export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 20;
export interface PagingBaseParams {
  page?: number;
  pageSize?: number;
}

export interface PagingSettleParams {
  page?: number;
  page_size?: number;
}
