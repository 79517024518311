import * as React from "react";
import { PageHeader, Row, Col, Button } from "antd";
import { translate } from "react-i18next";
import Grid from "antd/lib/card/Grid";
import ReactToPrint from "react-to-print";

interface LocalProps {
  //打印必须信息
}
class SettlePrintPage extends React.Component<{ t: any }> {
  componentRef: any;
  render() {
    let t = this.props.t;
    return (
      <div>
        <PageHeader title={t("title")} />
        <ReactToPrint
          trigger={() => (
            <div>
              <Button type="primary">打印</Button>
            </div>
          )}
          content={() => this.componentRef}
        />
        <SettlePrint t={this.props.t} ref={el => (this.componentRef = el)} />
      </div>
    );
  }
}
class SettlePrint extends React.Component<LocalProps & { t: any }> {
  render() {
    // let t = this.props.t;
    let headList = [
      "书名",
      "作者名",
      "结算区间",
      "分成比例",
      "保底金额",
      "销售额"
    ];
    let list: any[] = [];
    return (
      <Grid>
        <Row>推文结算确认单</Row>
        <Row>
          <div>
            <span>结算月份：</span>2019年1度
          </div>
          <div>
            <span>版权方：</span>逐浪网
          </div>
          <div>
            <span>销售额：</span>$2343.31
          </div>
          <div>
            <span>推广及结汇成本：</span>20%
          </div>
          <div>
            <span>总计书目：</span>1230
          </div>
        </Row>
        <Row>
          <div>应结算金额：$1234.43</div>
          <div>金额大写：捌仟叁佰玖拾肆圓</div>
        </Row>
        <Row>
          <Col span={12}>
            <div>付款方信息</div>
            <div>
              <span>企业名称：</span>xxxxxxx
            </div>
            <div>
              <span>纳税人识别号：</span>xxxxxxxxxxxxx
            </div>
            <div>
              <span>开户银行及账号：</span>xxxxxxxxx
            </div>
            <div>
              <span>注册地址：</span>xxxxxxxxxxx
            </div>
            <div>
              <span>地址：</span>xxxxxxxxxxxxxxxxxxxx
            </div>
            <div>
              <span>电话：</span>138111111111111
            </div>
            <div>
              <span>联系人：</span>xxxxx
            </div>
          </Col>
          <Col span={12}>
            <div>付款方信息</div>
            <div>
              <span>企业名称：</span>xxxxxxx
            </div>
            <div>
              <span>纳税人识别号：</span>xxxxxxxxxxxxx
            </div>
            <div>
              <span>开户银行及账号：</span>xxxxxxxxx
            </div>
            <div>
              <span>注册地址：</span>xxxxxxxxxxx
            </div>
            <div>
              <span>地址：</span>xxxxxxxxxxxxxxxxxxxx
            </div>
            <div>
              <span>电话：</span>138111111111111
            </div>
            <div>
              <span>联系人：</span>xxxxx
            </div>
          </Col>
        </Row>
        <Row>
          <div>声明单位（加盖公章）：</div>
          <div>_______年____月_____日</div>
        </Row>
        <Row>结算确认单附录</Row>
        <Row>
          <table>
            <tbody>
              <tr>{headList.map(item => <th key={item}>{item}</th>)}</tr>
              {list.map(item => <td key={item}>{item}</td>)}
            </tbody>
          </table>
        </Row>
      </Grid>
    );
  }
}
export default translate("settlePrint")(SettlePrintPage);
