import * as React from "react";
import { Book, adultGradeMap } from "app/models";
// import i18next = require("i18next");
import { translate, InjectedTranslateProps } from "react-i18next";
import { Checkbox, Radio } from "antd";
import { bookStore } from "../store";
import { toJS } from "mobx";

interface LocalProps {
  book: Book;
}

export const sensitivrContent = [
  { key: "X", value: "X" },
  { key: "M", value: "M" },
  { key: "O", value: "O" }
];

const textMap = {
  "1": "内容强度",
  "2": "出现频率",
  "3": "价值观"
};

class AdultGradeComponent extends React.Component<
  LocalProps & InjectedTranslateProps
> {
  render() {
    const { adultGrade, adultGradeValue } = bookStore;
    const options = Object.keys(adultGrade);
    console.log("--adultGradeCheckChange--", toJS(adultGrade));
    return (
      <div>
        <div>
          <Checkbox.Group
            options={adultGradeMap}
            defaultValue={options}
            onChange={e => {
              bookStore.adultGradeCheckChange(e);
            }}
          />
        </div>
        <div style={{ width: "400px", textAlign: "center" }}>
          {Object.keys(adultGrade).map((grade: any) => (
            <div>
              <p style={{ margin: 0 }}>{adultGradeValue[grade]}</p>
              <div>
                {Object.keys(adultGrade[grade]).map(contion => (
                  <div style={{ display: "flex", margin: "5px auto" }}>
                    <p
                      style={{
                        width: "150px",
                        textAlign: "right",
                        paddingRight: "10px"
                      }}
                    >
                      {textMap[(contion as unknown) as 1 | 2 | 3]}
                    </p>
                    <Radio.Group
                      onChange={e => {
                        const value = e.target.value;
                        adultGrade[grade][contion] = value;
                        bookStore.adultGrade = { ...adultGrade };
                      }}
                      value={adultGrade[grade][contion]}
                    >
                      {sensitivrContent.map(res => (
                        <Radio.Button value={res.key}>{res.value}</Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default translate("book")(AdultGradeComponent);
