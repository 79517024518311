import { observable, action } from "mobx";
import { runningWaterService } from "app/service";
import { SaleDetail, GetSalesDetailParam } from "app/models/RunningWater";
import { message } from "antd";
import moment = require("moment");
import i18next = require("i18next");
import * as XLSX from "xlsx";
// const data = require('./data.json');
const emptyQueryCondition = {
  page: 1,
  page_size: 10
};

class DailySaleRunningWaterStore {
  @observable salesList: SaleDetail[] = [];
  @observable total: number = 0;
  @observable loading: boolean = false;
  @observable queryCondition: GetSalesDetailParam = emptyQueryCondition;

  @action.bound
  async initData() {
    this.queryCondition = {
      ...this.queryCondition,
      from_date:
        this.getQueryString("from_date") || moment().format("YYYY-MM-DD"),
      to_date: this.getQueryString("to_date") || moment().format("YYYY-MM-DD")
    };
    await this.loadBooks();
  }

  @action.bound
  async loadBooks() {
    this.loading = true;
    try {
      const source = await runningWaterService.getSaleDetail(
        this.queryCondition
      );
      this.total = source.total;
      this.salesList = source.list;
      // this.salesList = data.data
      // this.total = data.total;
      // console.log("total");
    } catch (error) {
      message.error(error.message);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async resetData() {
    this.queryCondition = {
      ...this.queryCondition,
      page: 1,
      book_name: ""
    };
    this.initData();
  }

  getQueryString(name: string) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  }

  @action.bound
  async exportExl() {
    try {
      const queryCondition = {
        ...this.queryCondition,
        for_export: true
      };
      const saleDetailSource = await runningWaterService.getSaleDetail({
        ...queryCondition
      });
      const { list } = saleDetailSource;
      this.salesList = list;
      const sourceData = [
        [
          i18next.t("saleDetail:bookName"),
          i18next.t("saleDetail:englishBookName"),
          i18next.t("saleDetail:author"),
          i18next.t("saleDetail:copyrightOwner"),
          i18next.t("saleDetail:grade"),
          i18next.t("saleDetail:payingNumber"),
          i18next.t("saleDetail:totalFlow"),
          i18next.t("saleDetail:payMentFlow"),
          i18next.t("saleDetail:divideStream")
        ]
      ];
      for (let i = 0; i < this.salesList.length; i++) {
        sourceData.push([
          this.salesList[i].zhName,
          this.salesList[i].enName,
          this.salesList[i].author,
          this.salesList[i].cp,
          this.salesList[i].grade,
          this.salesList[i].babelIncome.uniqueCustomer,
          this.salesList[i].babelIncome.totalPaidChapter,
          this.salesList[i].babelIncome.totalBrickSales,
          this.salesList[i].babelIncome.totalSharedRevenue
        ]);
      }
      const source = XLSX.utils.aoa_to_sheet(sourceData);
      const wb = XLSX.utils.book_new();
      var ws_name = "Sheet1";
      XLSX.utils.book_append_sheet(wb, source, ws_name);
      const fileName = moment().format("YYYY/MM/DD");
      XLSX.writeFile(wb, `小说站日流水${fileName}.xlsx`);
    } catch (error) {
      throw error.message || "下载错误";
    }
  }
}
export const dailySaleRunningWaterStore = new DailySaleRunningWaterStore();
