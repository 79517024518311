import * as i18n from "i18next";
import * as LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    zh_CN: require("./zh_CN.json")
  },
  fallbackLng: "zh_CN",
  debug: true,

  // have a common namespace used around the full app
  ns: ["common", "book"],
  defaultNS: "common",
  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
