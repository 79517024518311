import BaseService from "./base";
import {
  UserManageParams,
  AddUserParams,
  UserInfo
} from "app/models/UserManage";
import { PageList } from "app/models/PageList";
import * as moment from "moment";
import { CPInfoList } from "app/models/RunningWater";
// import { toJS } from "mobx";
export default class UserManageService extends BaseService {
  adminUrl: string = `cp/admin/copyrightOwners`;

  public getUserManageList(
    condition: UserManageParams,
    cpList: CPInfoList[]
  ): Promise<PageList<UserInfo>> {
    return this.get(this.adminUrl, { ...condition }).then(res => {
      let source = res.data.data.list;
      source.map((data: any) => {
        const cpData = cpList.find((cp: CPInfoList) => cp.id == data.cp_id);
        // console.log("ddd", cpData, toJS(cpList), data.cp_id);
        data.cp = data.cp_id;
        data.cpName = cpData ? cpData.name : data.cp_id;
        data.create_time = moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss");
        data.remark = data.memo;
        data.update_time = moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss");
        data.user_name = data.name;
      });
      res.data.data.list = source;
      return res.data.data;
    });
  }

  public createUser(params: AddUserParams) {
    return this.post(this.adminUrl, {
      name: params.user_name,
      cp_id: params.cp,
      password: params.password,
      memo: params.remark
    });
  }

  public delectPower(id: string) {
    return this.put(`${this.adminUrl}/${id}/forbid`, {
      id
    }).then(res => res.data);
  }

  public updateUser(params: UserInfo) {
    return this.put(`${this.adminUrl}/${params.id}`, {
      name: params.user_name,
      cp_id: params.cp,
      memo: params.remark
    }).then(res => res.data);
  }
}
