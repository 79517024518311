import BaseService from "./base";
import * as cookies from "browser-cookies";
import { parseBoolean } from "app/utils";

const kMenuCollapsed = "MenuCollapsed";

export default class UserService extends BaseService {
  public login(username: string, password: string) {
    return this.post("/auth/ldap/login", {
      userName: username,
      password: password
    }).then(response => {
      this.setToken(response.data.data.token);
      return response;
    });
  }

  public getMenus() {
    return this.get("/user/menus").then(res => res.data);
  }

  public isLoggedIn(): boolean {
    return !!cookies.get("token");
  }

  public logout() {
    cookies.erase("token");
  }

  public setMenuCollapsed(collapsed: boolean) {
    return localStorage.setItem(kMenuCollapsed, JSON.stringify(collapsed));
  }

  public getUser() {
    return this.get("/auth/ldap/userInfo", {
      token: cookies.get("token")
    }).then(res => res.data.data);
  }

  public getMenuCollapsed(): boolean {
    return parseBoolean(localStorage.getItem(kMenuCollapsed) || true);
  }
}
