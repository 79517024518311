import { observable, action } from "mobx";
import { userService } from "app/service";
import { MenuItem, SubMenuItem } from "interface";
import { message } from "antd";
import { twNavigate } from "../route";
import { routerList } from "../../../config/routers.config";
import { LOGIN } from "config/path.config";

export interface RouterItem {
  page: React.ComponentType;
  path: string;
}

class DashboardStore {
  @observable public collapsed: boolean = false;
  @observable public menus: MenuItem[] = [];
  @observable public features: string[] = [];
  @observable public routers: any[] = [];

  setMenuCollapsed(collapsed: boolean) {
    userService.setMenuCollapsed(collapsed);
    this.collapsed = collapsed;
  }

  @action.bound
  initData() {
    this.collapsed = userService.getMenuCollapsed();
    this.getMenus();
  }

  @action.bound
  getMenus() {
    userService
      .getMenus()
      .then(res => {
        this.menus = res.data;
        // console.log("hhhhh", toJS(this.menus));
        this.updateRouters();
        let subMenu = this.getCurrentMenu(this.menus);
        if (!subMenu) return;
        this.features = subMenu.features;
        // twNavigate(window.location.pathname);) 不知道为什么加这段
      })
      .catch(error => message.error(error.message));
  }

  @action.bound
  updateRouters() {
    this.routers = routerList.filter(item => this.pathInMenus(item.path));
    // console.log("updateRouters", routerList, toJS(this.routers));
  }

  @action.bound
  updateFeatures() {
    let subMenu = this.getCurrentMenu(dashboardStore.menus);
    if (!subMenu) return;
    this.features = subMenu.features;
  }

  pathInMenus(path: string): Boolean {
    return !!this.menus.find(
      menu => !!menu.subMenus.find(subMenu => path.startsWith(subMenu.url))
    );
  }

  @action.bound
  getCurrentMenu(menus: MenuItem[]): undefined | SubMenuItem {
    let subMenu: undefined | SubMenuItem = undefined;
    menus.forEach(menu => {
      menu.subMenus.forEach(item => {
        if (window.location.pathname.startsWith(item.url)) {
          subMenu = item;
        }
      });
    });
    return subMenu;
  }

  @action.bound
  menuClick(menu: SubMenuItem) {
    this.features = menu.features;
    twNavigate(menu.url);
  }

  logout() {
    userService.logout();
    twNavigate(LOGIN);
  }
}
export const dashboardStore = new DashboardStore();
