export const LOGIN = "/login";
export const COPYRIGHTS = `/copyrights`;
export const BOOKS = `/books`;
export const SYSTEM_CONFIG = `/system-config`;
export const SETTLE_PRINT = `/print`;
export const DAILY_RUNNING_WATER = "/daily-sales";
export const SALE_DETAIL = "/sale-detail";
export const BOOK_RUNNING_WATER = "/book-sales";
export const USER_MANAGE = "/user-manage";
export const BOOK_RUNNING_WATER_NEW = "/book-sales-new";
