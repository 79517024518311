import { observable, action, toJS } from "mobx";
import { bookService, copyrightService } from "app/service";
import {
  Book,
  GetBooksParam,
  CreateBook,
  AdultGrade,
  adultGradeMap,
  UploadAdultGrade
} from "app/models";
import { message, Modal } from "antd";
import { Copyright } from "app/models/Copyright";
import { readXLSXFile } from "app/utils/read-xlsx";
import { showError } from "app/utils";
import moment = require("moment");
import { Decimal } from "decimal.js";
import { Languages } from "./components/update-book";
interface UpdateModal {
  visible: boolean;
  book: Book;
  loading: boolean;
}

const emptyUpdateModal: UpdateModal = {
  visible: false,
  book: {} as any,
  loading: false
};

interface GradeExcl {
  bookName: string;
  author: string;
  safe: string;
  sexy: string;
  badHabit: string;
  violenceAndAbuse: string;
  zh: string;
  zq: string;
  qt: string;
  copyrightName: string;
  gradeRemark: string;
  adultGrade: string;
}

interface CreateBooksModal {
  visible: boolean;
  valid: boolean;
  books: CreateBook[];
  parseExcelLoading: boolean;
  createLoading: boolean;
}

interface CreateGradeModal {
  visible: boolean;
  adultGrades: GradeExcl[];
  parseExcelLoading: boolean;
  createLoading: boolean;
}

const emptyCreateBooksModal: CreateBooksModal = {
  visible: false,
  valid: true,
  books: [],
  parseExcelLoading: false,
  createLoading: false
};

const emptyCreateGradeModal: CreateGradeModal = {
  visible: false,
  adultGrades: [],
  parseExcelLoading: false,
  createLoading: false
};

const TextLengthUnit = 10000;

const emptyQueryCondition = {
  status: 1,
  name: "",
  author: "",
  isCopyrightAuthorized: 1
};

class BookStore {
  @observable books: Book[] = [];
  @observable total: number = 0;
  @observable loading: boolean = false;
  @observable copyrights: Copyright[] = [];
  @observable copyrightMap = new Map();
  @observable queryCondition: GetBooksParam = emptyQueryCondition;
  @observable updateModal: UpdateModal = emptyUpdateModal;
  @observable createBooksModal: CreateBooksModal = emptyCreateBooksModal;
  @observable addBooksLoading: boolean = false;
  @observable addGradeLoading: boolean = false;
  @observable keywords: string[] = [];
  @observable adultGrade: AdultGrade = {};
  @observable adultGradeValue: AdultGrade = {};
  @observable isSafe: boolean = true;
  @observable createGradeModal: CreateGradeModal = emptyCreateGradeModal;

  @action.bound
  initData() {
    this.books = [];
    this.queryCondition = emptyQueryCondition;
    this.updateModal = emptyUpdateModal;
    this.loadBooks();
    this.loadCopyright();
    this.getKeywords();
    this.formatAdultGradeMap();
  }

  getCopyrightNameById(id: number) {
    let copyright = this.copyrightMap.get(id);
    if (copyright) return copyright.name;
    return "";
  }

  formatAdultGradeMap() {
    let value: any = {};
    adultGradeMap.map(res => {
      value[res.value] = res.label;
    });
    this.adultGradeValue = value;
    console.log("===", toJS(this.adultGradeValue));
  }

  @action.bound
  async loadBooks() {
    this.loading = true;
    try {
      let { total, list } = await bookService.getBooks(this.queryCondition);
      this.books = list.map(item => {
        item.textLength = (item.textLength || 0) / TextLengthUnit;
        try {
          if (item.gainsharingRate) {
            item.gainsharingRate = new Decimal(item.gainsharingRate || "0")
              .times(100)
              .toString();
          }
        } catch (error) {
          item.gainsharingRate = "";
          console.log(error);
        }
        return item;
      });
      this.total = total;
    } catch (error) {
      message.error(error.message);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async getKeywords() {
    const data = await bookService.getAllKeywords();
    this.keywords = data;
    console.log("data", data);
  }

  @action.bound
  async loadCopyright() {
    try {
      const { copyrightDetails } = await copyrightService.query({
        pageSize: 1000,
        pageNumber: 1
      });
      this.copyrights = copyrightDetails;
      this.copyrights.forEach(item => {
        this.copyrightMap.set(item.id, item);
      });
    } catch (error) {
      message.error(error.message);
    }
  }

  @action.bound
  async parseGradeExcleToData(file: any) {
    this.createGradeModal.parseExcelLoading = true;
    this.createGradeModal.adultGrades = [];
    const header = [
      "bookName",
      "author",
      "copyrightName",
      "safe",
      "sexy",
      "badHabit",
      "violenceAndAbuse",
      "zh",
      "zq",
      "qt",
      "gradeRemark",
      ""
    ];

    try {
      let data = (await readXLSXFile(file, { header: header })) as any;
      this.createGradeModal.adultGrades = data.slice(1, data.length);
    } catch (error) {
      this.createBooksModal.parseExcelLoading = false;
      showError(error);
    }
  }

  @action.bound
  async parseExcelToData(file: any) {
    this.createBooksModal.valid = true;
    this.createBooksModal.parseExcelLoading = true;
    this.createBooksModal.books = [];
    const header = [
      "name",
      "author",
      "textLength",
      "targetAudience",
      "zhGenres",
      "isCompleted",
      "copyrightName",
      "grade",
      "authorizationStartAt",
      "authorizationEndAt",
      "gainsharingRate",
      "guaranteedAmount",
      "authorizationChannel",
      "authorizationLanguage",
      "isMutualAuthorization",
      "remark",
      "sensitivityRating",
      "language",
      "isbn"
    ];
    try {
      let data = (await readXLSXFile(file, { header: header })) as any[];
      this.createBooksModal.parseExcelLoading = false;
      this.createBooksModal.books = data
        .slice(1, data.length)
        .map(item => {
          const copyrightItem: any = {};
          Object.keys(item).forEach(ele => {
            copyrightItem[ele] = String(item[ele]).trim();
          });
          return copyrightItem;
        })
        .map(item => {
          let gainsharingRate = null;
          let guaranteedAmount = null;
          let copyrightId = 0;
          let targetAudience = 0;
          let isCompleted = -1;
          let isMutualAuthorization = -1;
          if (item.targetAudience && item.targetAudience === "男频") {
            targetAudience = 1;
          }
          if (item.targetAudience && item.targetAudience === "女频") {
            targetAudience = 2;
          }
          if (item.isCompleted && item.isCompleted === "完结") {
            isCompleted = 1;
          }
          if (item.isCompleted && item.isCompleted === "连载") {
            isCompleted = 0;
          }
          if (
            item.isMutualAuthorization &&
            item.isMutualAuthorization === "Y"
          ) {
            isMutualAuthorization = 1;
          }
          if (
            item.isMutualAuthorization &&
            item.isMutualAuthorization === "N"
          ) {
            isMutualAuthorization = 0;
          }
          try {
            if (item.gainsharingRate) {
              gainsharingRate = new Decimal(item.gainsharingRate).toString();
            }
          } catch (error) {
            gainsharingRate = item.gainsharingRate;
            console.log(error);
          }
          try {
            if (item.guaranteedAmount) {
              guaranteedAmount = new Decimal(item.guaranteedAmount).toString();
            }
          } catch (error) {
            guaranteedAmount = item.guaranteedAmount;
            console.log(error);
          }
          const findCopyrightIdByName = this.copyrights.find(
            ele => ele.name === item.copyrightName
          );
          if (findCopyrightIdByName) {
            copyrightId = findCopyrightIdByName.id;
          }
          return {
            name: item.name,
            copyrightName: item.copyrightName,
            copyrightId,
            author: item.author,
            authorizationStartAt: item.authorizationStartAt,
            authorizationEndAt: item.authorizationEndAt,
            authorizationChannel: item.authorizationChannel,
            grade: item.grade,
            sensitivityRating: item.sensitivityRating || "",
            gainsharingRate,
            guaranteedAmount,
            textLength: item.textLength || "",
            targetAudience,
            zhGenres: item.zhGenres || "",
            isCompleted,
            authorizationLanguage: item.authorizationLanguage,
            isMutualAuthorization,
            remark: item.remark || "",
            language: item.language,
            isbn: item.isbn || ""
          };
        });
      console.log(
        toJS(this.createBooksModal.books),
        "this.createBooksModal.books"
      );
    } catch (err) {
      this.createBooksModal.parseExcelLoading = false;
      showError(err);
    }
  }

  @action.bound
  async addBooks() {
    if (!this.createBooksModal.valid) {
      message.error("请检查上传内容是否符合要求");
      return;
    }
    this.addBooksLoading = true;
    try {
      let books: CreateBook[] = JSON.parse(
        JSON.stringify(this.createBooksModal.books)
      );
      if (!books.length) {
        message.error("上传内容为空，请检查你的文档!");
        return;
      }
      books = books.map(item => {
        item.textLength = (item.textLength || 0) * TextLengthUnit;
        if (item.gainsharingRate) {
          item.gainsharingRate = new Decimal(item.gainsharingRate!)
            .div(100)
            .toString();
        }
        if (item.guaranteedAmount) {
          item.guaranteedAmount = new Decimal(item.guaranteedAmount)
            .toFixed(2)
            .toString();
        }
        if (item.authorizationLanguage) {
          const targetAuthorizationLanguage: string[] = [];
          const filtrationAuthorizationLanguage = item.authorizationLanguage.replace(
            /，/g,
            ","
          );
          const authorizationLanguageArray = filtrationAuthorizationLanguage.split(
            ","
          );
          authorizationLanguageArray.forEach(language => {
            if (language) {
              const { value } = Languages.find(
                ({ label }) => label === language
              ) || { value: "" };
              if (value && !targetAuthorizationLanguage.includes(value)) {
                targetAuthorizationLanguage.push(value);
              }
            }
          });
          item.authorizationLanguage = targetAuthorizationLanguage.join(",");
        }
        return item;
      });
      await bookService.createBooks(books);
      this.createBooksModal = emptyCreateBooksModal;
      message.success("上传成功！");
    } catch (error) {
      message.error(error.message);
    } finally {
      this.addBooksLoading = false;
    }
  }

  async updatAdultGrade() {
    this.addGradeLoading = true;
    try {
      let adultGrade: GradeExcl[] = JSON.parse(
        JSON.stringify(this.createGradeModal.adultGrades)
      );
      let errorList = [];
      let rightList: UploadAdultGrade[] = [];
      let regx = /[XMO]{3}/;
      for (let index = 0; index < adultGrade.length; index++) {
        let source: GradeExcl = { ...adultGrade[index] };
        if (!source.author || !source.bookName || !source.copyrightName) {
          errorList.push({ ...source, errorInfo: "缺少作者/版权/书名" });
          continue;
        }
        let copyright = this.copyrights.find(
          res => res.name == source.copyrightName.trim()
        );
        if (!copyright) {
          errorList.push({ ...source, errorInfo: "版权有问题" });
          continue;
        }

        if (!adultGrade[index].safe) {
          errorList.push({ ...source, errorInfo: "安全必填" });
          continue;
        }

        delete source.author;
        delete source.author;
        delete source.bookName;
        delete source.safe;
        delete source.copyrightName;
        delete source.gradeRemark;
        let iscontinue = false;
        let sourceList = Object.keys(source);
        if (sourceList.length > 0 && adultGrade[index].safe != "否") {
          errorList.push({
            ...adultGrade[index],
            errorInfo: "安全但是敏感信息有"
          });
          continue;
        }
        adultGrade[index].adultGrade =
          adultGrade[index].safe == "是" ? "安全" : "危险";
        for (let i = 0; i < sourceList.length; i++) {
          console.log(
            "source[sourceList[i] as keyof GradeExcl]",
            source[sourceList[i] as keyof GradeExcl]
          );
          let senseCOntent = source[
            sourceList[i] as keyof GradeExcl
          ].toLocaleUpperCase();
          if (!regx.test(senseCOntent)) {
            errorList.push({
              ...adultGrade[index],
              errorInfo: "敏感信息有问题"
            });
            iscontinue = true;
            break;
          }
          adultGrade[index][sourceList[i] as keyof GradeExcl] = senseCOntent;
          adultGrade[index].adultGrade += `,${sourceList[i]}-${senseCOntent}`;
        }
        if (iscontinue) {
          continue;
        }
        rightList.push({
          ...adultGrade[index],
          copyrightId: copyright.id + "",
          safe: adultGrade[index].safe == "是" ? "安全" : "危险",
          gradeRemark: adultGrade[index].gradeRemark
            ? adultGrade[index].gradeRemark + ""
            : ""
        });
      }
      if (errorList.length > 0) {
        let errorBookName = errorList.reduce(
          (a, b) => (a += `《${b.bookName}》`),
          ""
        );
        Modal.error({
          content: `${errorBookName}书数据有问题`
        });
        return;
      }
      const data = await bookService.updateGrade(rightList);
      this.createGradeModal = emptyCreateGradeModal;
      console.log("--updateGrade-", data);
      if (data.length > 0) {
        throw new Error(`${JSON.stringify(data)}书有问题`);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      this.addGradeLoading = false;
    }
  }

  async updateBook(book: Book) {
    let params = JSON.parse(JSON.stringify(book));
    if (params.gainsharingRate) {
      params.gainsharingRate = new Decimal(params.gainsharingRate!)
        .div(100)
        .toString();
    }
    if (params.guaranteedAmount) {
      params.guaranteedAmount = new Decimal(params.guaranteedAmount)
        .toFixed(2)
        .toString();
    }
    if (params.authorizationLanguage) {
      params.authorizationLanguage = params.authorizationLanguage.join();
    }
    params.textLength = (params.textLength || 0) * TextLengthUnit;
    params.authorizationStartAt = moment(params.authorizationStartAt).format(
      "YYYY-MM-DD"
    );
    params.authorizationEndAt = moment(params.authorizationEndAt).format(
      "YYYY-MM-DD"
    );
    params.zhGenres = params.zhGenres;
    let data = this.isSafe ? "安全" : "危险";
    if (this.isSafe) {
      params.adultGrade = data;
    } else {
      let adult = Object.keys(this.adultGrade).map(res => {
        let status = Object.keys(this.adultGrade[res]).map((status: any) => {
          return this.adultGrade[res][status];
        });
        return `${res}-${status.join("")}`;
      });
      params.adultGrade = `${data},${adult.join(",")}`;
    }

    this.updateModal.loading = true;
    delete params.isSafe;

    try {
      await bookService.updateBooks(this.updateModal.book.id, params);
      this.updateModal.visible = false;
      this.loadBooks();
    } catch (error) {
      message.error(error.message);
    } finally {
      this.updateModal.loading = false;
    }
  }

  async exportBooks() {
    try {
      await bookService.exportBooks(this.queryCondition);
      message.info("邮件已发，注意查收");
    } catch (error) {
      message.error(error.message);
    }
  }

  async adultGradeCheckChange(data: any) {
    let source: AdultGrade = {};
    data.map((res: string) => {
      if (this.adultGrade[res]) {
        source[res] = this.adultGrade[res];
      } else {
        source[res] = {
          1: "",
          2: "",
          3: ""
        };
      }
      return res;
    });
    this.adultGrade = { ...source };
  }
}
export const bookStore = new BookStore();
