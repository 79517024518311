import * as React from "react";
import {
  Card,
  Table,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
  Divider
} from "antd";
import { bookRunningWaterStore } from "./store";
import { observer } from "mobx-react";
import moment = require("moment");
// import UpdateBookModal from "./components/update-book";
import { translate, InjectedTranslateProps } from "react-i18next";
import i18next = require("i18next");

const { RangePicker } = DatePicker;

@observer
class BookRunningWaterNewPage extends React.Component<InjectedTranslateProps> {
  baseColumns = [
    {
      title: i18next.t("saleDetail:bookName"),
      dataIndex: "zhName",
      key: "zhName"
    },
    {
      title: i18next.t("saleDetail:englishBookName"),
      dataIndex: "enName",
      key: "enName"
    },
    {
      title: i18next.t("saleDetail:author"),
      dataIndex: "author",
      key: "author"
    },
    {
      title: i18next.t("saleDetail:copyrightOwner"),
      dataIndex: "cp",
      key: "cp"
    },
    {
      title: i18next.t("saleDetail:grade"),
      dataIndex: "grade",
      key: "grade"
    },
    {
      title: i18next.t("saleDetail:payingNumber"),
      dataIndex: "babelIncome.uniqueCustomer",
      key: "uniqueCustomer"
    },
    {
      title: i18next.t("saleDetail:totalPaidSections"),
      dataIndex: "babelIncome.totalPaidChapter",
      key: "totalPaidChapter"
    },
    {
      title: i18next.t("saleDetail:totalFlow"),
      dataIndex: "babelIncome.totalBrickSales",
      key: "totalBrickSales"
    },
    {
      title: i18next.t("saleDetail:divideStream"),
      dataIndex: "babelIncome.totalSharedRevenue",
      key: "totalSharedRevenue"
    }
  ];

  componentDidMount() {
    bookRunningWaterStore.initData();
  }

  render() {
    return (
      <div>
        {this.renderSearch()}
        {this.renderTable()}
      </div>
    );
  }

  renderTable() {
    return (
      <Table
        dataSource={[...bookRunningWaterStore.salesList]}
        columns={this.baseColumns as any}
        loading={bookRunningWaterStore.loading}
        rowKey="enName"
        pagination={{
          total: bookRunningWaterStore.total,
          current: bookRunningWaterStore.queryCondition.page,
          pageSize: bookRunningWaterStore.queryCondition.page_size,
          showSizeChanger: true,
          showQuickJumper: true
        }}
        onChange={(
          { total, current, pageSize },
          filters,
          { columnKey, order }
        ) => {
          bookRunningWaterStore.queryCondition = {
            ...bookRunningWaterStore.queryCondition,
            page_size: pageSize,
            page: current
          };
          console.log(
            bookRunningWaterStore.queryCondition,
            bookRunningWaterStore.total
          );
          // if (columnKey && order) {
          //   order === "descend" ? "desc" : "asc";
          //   // bookRunningWaterStore.queryCondition.order = order;
          //   bookRunningWaterStore.initData();
          //   console.log('order', order, 'columnKey', columnKey)
          // }
          bookRunningWaterStore.loadBooks();
        }}
      />
    );
  }

  renderSearch() {
    let t = this.props.t;
    let { cp = "", book_name = "" } = bookRunningWaterStore.queryCondition;
    let { cpList, queryCondition } = bookRunningWaterStore;
    return (
      <Card>
        <Form layout="inline">
          <Form.Item label={t("saleDetail:bookName")}>
            <Input
              value={book_name}
              placeholder={t("common:pleaseInput")}
              onChange={(eve: any) => {
                bookRunningWaterStore.queryCondition = {
                  ...bookRunningWaterStore.queryCondition,
                  book_name: eve.target.value
                };
              }}
            />
          </Form.Item>
          <Form.Item label={t("saleDetail:copyrightOwner")}>
            <Select
              style={{ width: 160 }}
              value={cp}
              showSearch
              allowClear
              optionFilterProp="children"
              onChange={e => {
                // console.log("onchange", e);
                bookRunningWaterStore.queryCondition = {
                  ...bookRunningWaterStore.queryCondition,
                  cp: e
                };
              }}
              placeholder={t("saleDetail:copyrightOwner")}
            >
              {cpList.map((item: any) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t("saleDetail:timeScreening")}>
            <RangePicker
              value={
                bookRunningWaterStore.queryCondition.from_date &&
                bookRunningWaterStore.queryCondition.to_date
                  ? [
                      moment(
                        bookRunningWaterStore.queryCondition.from_date,
                        "YYYY-MM-DD"
                      ),
                      moment(
                        bookRunningWaterStore.queryCondition.to_date,
                        "YYYY-MM-DD"
                      )
                    ]
                  : []
              }
              onChange={(eve: any) => {
                bookRunningWaterStore.queryCondition = {
                  ...queryCondition,
                  from_date: eve[0].format("YYYY-MM-DD"),
                  to_date: eve[1].format("YYYY-MM-DD")
                };
                // console.log("qqq", bookRunningWaterStore);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                bookRunningWaterStore.queryCondition.page = 1;
                bookRunningWaterStore.searchData();
              }}
            >
              {t("common:search")}
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              onClick={() => {
                bookRunningWaterStore.resetData();
              }}
            >
              {t("common:reset")}
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              onClick={() => bookRunningWaterStore.exportExl()}
            >
              {t("common:export")}
            </Button>
          </Form.Item>
        </Form>
        <h3 style={{ marginTop: 20 }}>
          {bookRunningWaterStore.queryCondition.from_date}～
          {bookRunningWaterStore.queryCondition.to_date}书流水
        </h3>
      </Card>
    );
  }
}

export default translate("dayilRunningWater")(BookRunningWaterNewPage);
