import { bookStore } from "./store";

const validate50Words = (text: string) => text && text.length <= 50;

const validateGainSharingRateNumber = (text: string) =>
  text === null ||
  (/(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0){1}$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/.test(
    text
  ) &&
    Number(text) < 100);

const validateGuaranteedAmount = (text: string) =>
  text === null ||
  (/(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0){1}$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/.test(
    text
  ) &&
    Number(text) < 100000000000);

const validateNumberRequire = (text: string) => {
  return (
    /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,4})?$)|(?:^(?:0){1}$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/.test(
      text
    ) && Number(text) <= 100000
  );
};

const validateChannel = (text: number) => [1, 2].includes(text);

const validateZHGenres = (text: string) => text && text.length <= 24;

const validateIsCompleted = (text: number) => [0, 1].includes(text);

const validateGrade = (text: string) =>
  ["SS", "S", "A", "B", "C"].includes(text);

const validateAuthorizationChannel = (text: string) =>
  ["KINDLE_ONLY", "TOP_FIVE", "ALL", "NONE"].includes(text);

const validateAuthorizationLanguage = (text: string) => {
  if (!text) return false;
  const filtrationAuthorizationLanguage = text.replace(/，/g, ",");
  const authorizationLanguageArray =
    filtrationAuthorizationLanguage.split(",") || [];
  return authorizationLanguageArray.every(item =>
    ["繁体中文", "英文", "西班牙文", "俄文", "印尼文", "其他"].includes(item)
  );
};

const validateIsMutualAuthorization = (text: number) => [0, 1].includes(text);

const validateLanguage = (text: string) => ["zh", "spa", "eng"].includes(text);

const validateRemark = (text: string) => text.length <= 300;

const validateIsbn = (text: string) => text.length <= 20;

const validateSensitivityRating = (text: string) =>
  text === "" || ["A", "B", "C", "D"].includes(text);

const validateDate = (text: string) =>
  /^\d{4}(-)(1[0-2]|0?\d)\1([0-2]\d|\d|30|31)$/.test(text);

const validateCopyright = (text: string, row: any) => {
  return bookStore.copyrights
    .map(item => item.name)
    .includes(row.copyrightName);
};

export const validationMap = {
  name: validate50Words,
  author: validate50Words,
  textLength: validateNumberRequire,
  targetAudience: validateChannel,
  zhGenres: validateZHGenres,
  isCompleted: validateIsCompleted,
  grade: validateGrade,
  gainsharingRate: validateGainSharingRateNumber,
  guaranteedAmount: validateGuaranteedAmount,
  authorizationChannel: validateAuthorizationChannel,
  authorizationLanguage: validateAuthorizationLanguage,
  isMutualAuthorization: validateIsMutualAuthorization,
  authorizationStartAt: validateDate,
  authorizationEndAt: validateDate,
  sensitivityRating: validateSensitivityRating,
  language: validateLanguage,
  isbn: validateIsbn,
  remark: validateRemark,
  copyrightId: validateCopyright
};
