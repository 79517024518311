import { PageParam } from "../models/PageParam";
import {
  Copyright,
  CreateCopyrightController,
  UpdateCopyrightController
} from "./../models/Copyright";
import BaseService from "./base";
export interface CopyrightCondition extends PageParam {
  name?: string;
  pageNumber?: number;
}
export default class CopyrightService extends BaseService {
  hostPrefix: string = "/lunker/copyrights";
  async query(condition: CopyrightCondition): Promise<any> {
    const res = await this.get(`${this.hostPrefix}`, condition);
    return res.data.data;
  }
  async getById(id: number): Promise<Copyright> {
    const res = await this.get(`${this.hostPrefix}/${id}`);
    return res.data.data;
  }
  async create(copyright: Partial<Copyright>): Promise<Copyright> {
    const res = await this.post(`${this.hostPrefix}`, copyright);
    return res.data.data;
  }
  async createCopyrightController(
    copyright: Partial<CreateCopyrightController>
  ): Promise<CreateCopyrightController> {
    const res = await this.post(`/corpus/copyright`, copyright);
    return res.data.data;
  }
  async updateCopyrightController(
    id: number,
    copyright: Partial<UpdateCopyrightController>
  ): Promise<UpdateCopyrightController> {
    const res = await this.put(`/corpus/copyright/${id}`, copyright);
    return res.data.data;
  }
  async update(id: number, copyright: Partial<Copyright>) {
    const res = await this.put(`${this.hostPrefix}/${id}`, copyright);
    return res.data.data;
  }
}
