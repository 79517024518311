import * as React from "react";
import * as styles from "./styles.scss";
import { Menu, Icon } from "antd";
import { translate } from "react-i18next";
import { MenuItem, SubMenuItem } from "interface";
import { dashboardStore } from "../../store";
// import { toJS } from "mobx";
const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item as any;

interface LocalProps {
  menus: MenuItem[];
}

class MenuLayout extends React.Component<LocalProps> {
  render() {
    return (
      <Menu
        mode="inline"
        className={styles.menu}
        defaultOpenKeys={this.props.menus.map(item => item.url)}
        openKeys={
          dashboardStore.collapsed ? [] : this.props.menus.map(item => item.url)
        }
        defaultSelectedKeys={[
          window.location.pathname
            .split("/")
            .slice(0, 3)
            .join("/")
        ]}
      >
        {this.renderMenu(this.props.menus)}
      </Menu>
    );
  }

  renderMenu(list: any[]) {
    // console.log("renderMenu", toJS(list));
    return list.map(item => {
      return (
        <SubMenu
          key={item.url}
          title={
            <span>
              {item.icon ? <Icon type={item.icon} /> : null}
              <span>{item.title}</span>
            </span>
          }
        >
          {this.renderSubMenuItems(item.subMenus)}
        </SubMenu>
      );
    });
  }

  renderSubMenuItems(list: SubMenuItem[]) {
    return list.map(item => {
      return (
        <MenuItem key={item.url} onClick={() => dashboardStore.menuClick(item)}>
          <span>{item.title}</span>
        </MenuItem>
      );
    });
  }
}

export default translate("menu")(MenuLayout);
