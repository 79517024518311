import { User } from "./../../../interface/user";
import { observable, action } from "mobx";
import { twNavigate } from "app/pages/route";
import { message } from "antd";
import { userService } from "app/service";

class LoginStore {
  @observable username: string = "";
  @observable password: string = "";
  @observable loading: boolean = false;
  @observable user?: User;
  @observable showErr: boolean = false;

  @action.bound
  async login() {
    if (this.loading) return;
    try {
      this.loading = true;
      const SUCCESS_HINT_DURATION = 1;
      await userService.login(this.username, this.password);
      message.success("登录成功，即将为您跳转", SUCCESS_HINT_DURATION);
      setTimeout(async () => {
        this.navigateToDashboard();
        this.initUser();
      }, SUCCESS_HINT_DURATION * 1000);
    } catch (error) {
      this.showErr = true;
      message.error("登录失败，" + error.message);
    } finally {
      this.loading = false;
    }
  }
  @action.bound
  async initUser() {
    this.user = await userService.getUser();
  }
  @action.bound
  navigateToDashboard() {
    twNavigate("/");
  }
}

export const loginStore = new LoginStore();
