import CopyrightService from "./copyright";
import UserService from "./user";
import BookService from "./book";
import SystemConfigService from "./systemConfig";
import RunningWaterService from "./runningWater";
export const copyrightService = new CopyrightService();
export const userService = new UserService();
export const bookService = new BookService();
export const systemConfigService = new SystemConfigService();
export const runningWaterService = new RunningWaterService();
