const urlParamsMap = {
  test: {
    CROW_ADMI: "http://test-crowdsourcing.tuiwen-tech.cn/booksList",
    CROW_CLIENT: "http://test-crowdsourcing.funstory.com.cn/task",
    CP_ADMIN: "http://test-cp.tuiwen-tech.cn/books",
    CP_CLIENT: "http://test-jiesuan.funstory.com.cn/dailySale",
    CROW_REVISE: "http://test-revision.funstory.com.cn/revise",
    TRANSLATIION: "http://test-translation.tuiwen-tech.cn"
  },
  production: {
    CROW_ADMI: "http://crowdsourcing.tuiwen-tech.cn/booksList",
    CROW_CLIENT: "http://crowdsourcing.funstory.com.cn/task",
    CP_ADMIN: "http://cp.tuiwen-tech.cn/books",
    CP_CLIENT: "http://jiesuan.funstory.com.cn/dailySale",
    CROW_REVISE: "http://revision.funstory.com.cn/revise",
    TRANSLATIION: "http://translation.tuiwen-tech.cn"
  }
};
let key = process.env.RUN_ENV
  ? process.env.RUN_ENV == "development"
    ? "test"
    : process.env.RUN_ENV
  : "test";
console.log(key);
export const urlParams = (urlParamsMap as any)[key];
