import { RouterItem } from "app/pages/layout/store";

import {
  BOOKS,
  COPYRIGHTS,
  SYSTEM_CONFIG,
  SETTLE_PRINT,
  DAILY_RUNNING_WATER,
  SALE_DETAIL,
  BOOK_RUNNING_WATER,
  USER_MANAGE,
  BOOK_RUNNING_WATER_NEW
} from "./path.config";
import BookPage from "app/pages/book";
import CopyrightPage from "app/pages/copyright";
import SystemConfigPage from "app/pages/systemConfig";
import SettlePrintPage from "app/pages/settlePrint";
import WaterPage from "app/pages/dailyRunningWater";
import SaleDetailPage from "app/pages/saleDetail";
import BookRunningWaterPage from "app/pages/bookRunningWater";
import BookRunningWaterNewPage from "app/pages/bookRunningWaterNew";
import userManagePage from "app/pages/userManage";

export const routerList: RouterItem[] = [
  {
    page: BookPage,
    path: BOOKS
  },
  {
    page: CopyrightPage,
    path: COPYRIGHTS
  },
  {
    page: SystemConfigPage,
    path: SYSTEM_CONFIG
  },
  {
    page: SettlePrintPage,
    path: SETTLE_PRINT
  },
  {
    page: WaterPage,
    path: DAILY_RUNNING_WATER
  },
  {
    page: SaleDetailPage,
    path: SALE_DETAIL
  },
  {
    page: BookRunningWaterPage,
    path: BOOK_RUNNING_WATER
  },
  {
    page: userManagePage,
    path: USER_MANAGE
  },
  {
    page: BookRunningWaterNewPage,
    path: BOOK_RUNNING_WATER_NEW
  }
];
