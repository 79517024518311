import * as React from "react";
import { Router, navigate } from "@reach/router";
import { dashboardStore } from "../layout/store";
export default class PageRouter extends React.Component<any> {
  render() {
    // console.log("routers", toJS(dashboardStore.routers));
    return (
      <Router>
        {dashboardStore.routers.map(item => (
          <item.page path={item.path} key={item.path} />
        ))}
      </Router>
    );
  }
}

export function twNavigate(path: string, params?: any) {
  try {
    let url: string = path;
    let paramsString = "";
    for (let key in params) {
      if (url.indexOf(key) >= 0) {
        url = url.replace(
          `:${key}`,
          encodeURIComponent(params[`${key}`].toString())
        );
      } else {
        paramsString += (paramsString.length && "&") || "";
        paramsString += key + "=" + params[`${key}`].toString();
      }
    }
    url = paramsString.length ? url + "?" + paramsString : url;
    navigate(url.replace(/\/\*$/, ""));
  } catch (error) {
    console.error(error);
  }
}
