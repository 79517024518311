import * as React from "react";
import {
  Modal,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Divider,
  Form,
  Radio
} from "antd";
import { Book, AdultGrade } from "app/models";
import { FormSection } from "app/components/FormSection";
import i18next = require("i18next");
import moment = require("moment");
import { Copyright } from "app/models/Copyright";
import { translate, InjectedTranslateProps } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import AdultGradeComponent from "./adultGrade";
import { bookStore } from "../store";

interface LocalProps {
  visible: boolean;
  book: Book;
  copyrights: Copyright[];
  loading: boolean;
  adultGrade: AdultGrade;
  isSafe: boolean;
  onCancel?: () => void;
  onUpdate?: (params: Book) => void;
}

const FormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const MutualAuthorization = [
  { label: "是", value: 1 },
  { label: "否", value: 0 }
];
const Channels = [
  { label: "KINDLE_ONLY", value: "KINDLE_ONLY" },
  { label: "TOP_FIVE", value: "TOP_FIVE" },
  { label: "ALL", value: "ALL" },
  { label: "NONE", value: "NONE" }
];
const Languages = [
  { label: "英文", value: "EN" },
  { label: "繁体中文", value: "CHT" },
  { label: "西班牙文", value: "ES" },
  { label: "俄文", value: "RU" },
  { label: "印尼文", value: "ID" },
  { label: "其他", value: "OTH" }
];
const Grades = [
  { label: "SS", value: "SS" },
  { label: "S", value: "S" },
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" }
];
const TargetAudiences = [
  { label: "男频", value: 1 },
  { label: "女频", value: 2 }
];
const UpdateStatus = [
  { label: "已完结", value: 1 },
  { label: "连载", value: 0 }
];
class UpdateBookModal extends React.Component<
  LocalProps & InjectedTranslateProps
> {
  render() {
    let book = this.props.book;
    let copyrights = this.props.copyrights.map(item => {
      return {
        label: item.name,
        value: item.id
      };
    });
    let t = this.props.t;
    return (
      <Modal
        title={"更新书目"}
        width={"70%"}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={<div />}
      >
        <FormSection.Form
          name="UpdateBook"
          onSubmit={(values: any) => {
            this.props.onUpdate && this.props.onUpdate(values);
          }}
        >
          <Row>
            <Col span={12}>
              {this.renderInputItem(t("name"), "name", book.name)}
              {this.renderInputItem(t("author"), "author", book.author)}
              {this.renderInputItem(
                t("textLength"),
                "textLength",
                book.textLength
              )}
              {this.renderSelectItem(
                t("targetAudience"),
                "targetAudience",
                TargetAudiences,
                book.targetAudience
              )}
              {this.renderSelectItem(
                t("isCompleted"),
                "isCompleted",
                UpdateStatus,
                book.isCompleted
              )}
              {this.renderSelectItem(
                t("copyrightId"),
                "copyrightId",
                copyrights,
                book.copyrightId
              )}
              {this.renderSelectItem(t("grade"), "grade", Grades, book.grade)}
              {this.renderInputItem(
                t("zhGenres"),
                "zhGenres",
                book.zhGenres || ""
              )}
            </Col>
            <Col span={12}>
              {this.renderDatePickerItem(
                t("authorizationStartAt"),
                "authorizationStartAt",
                book.authorizationStartAt
              )}
              {this.renderDatePickerItem(
                t("authorizationEndAt"),
                "authorizationEndAt",
                book.authorizationEndAt
              )}
              {this.renderInputItem(
                t("gainsharingRate"),
                "gainsharingRate",
                book.gainsharingRate,
                []
              )}
              {this.renderInputItem(
                t("guaranteedAmount"),
                "guaranteedAmount",
                book.guaranteedAmount,
                []
              )}
              {this.renderSelectItem(
                t("authorizationChannel"),
                "authorizationChannel",
                Channels,
                book.authorizationChannel
              )}
              {this.renderSelectItemMultiple(
                t("authorizationLanguage"),
                "authorizationLanguage",
                Languages,
                book.authorizationLanguage
              )}
              {this.renderSelectItem(
                t("isMutualAuthorization"),
                "isMutualAuthorization",
                MutualAuthorization,
                book.isMutualAuthorization
              )}
              {this.renderTextArea(t("remark"), "remark", book.remark)}
              {this.renderTextArea(
                "成人评级备注",
                "gradeRemark",
                book.gradeRemark
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormSection.FormItem
                {...FormItemLayout}
                label="是否安全"
                name="isSafe"
                options={{
                  initialValue: bookStore.isSafe,
                  rules: [{ required: true }]
                }}
              >
                <Radio.Group
                  onChange={e => {
                    bookStore.isSafe = e.target.value;
                  }}
                  value={bookStore.isSafe}
                >
                  <Radio value={false}>否</Radio>
                  <Radio value={true}>是</Radio>
                </Radio.Group>
              </FormSection.FormItem>
            </Col>
          </Row>

          {bookStore.isSafe == false ? (
            <Row>
              <Col span={16} offset={2}>
                <FormSection.FormItem label="审核评级" name="adultGrade">
                  <AdultGradeComponent book={book} />
                </FormSection.FormItem>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col span={12} offset={12}>
              <Form.Item style={{ textAlign: "center" }}>
                <Button onClick={this.props.onCancel}>取消</Button>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.props.loading}
                >
                  保存
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </FormSection.Form>
      </Modal>
    );
  }

  renderInputItem(
    label: string,
    name: string,
    initialValue?: string | number,
    rules?: any
  ) {
    return (
      <FormSection.FormItem
        {...FormItemLayout}
        label={label}
        name={name}
        options={{ initialValue, rules: rules || [{ required: true }] }}
      >
        <Input placeholder={i18next.t("common:pleaseInput")} />
      </FormSection.FormItem>
    );
  }

  renderTextArea(label: string, name: string, initialValue?: string | number) {
    return (
      <FormSection.FormItem
        {...FormItemLayout}
        label={label}
        name={name}
        options={{ initialValue }}
      >
        <TextArea placeholder={i18next.t("common:pleaseInput")} />
      </FormSection.FormItem>
    );
  }

  renderDatePickerItem(
    label: string,
    name: string,
    initialValue?: string | number
  ) {
    return (
      <FormSection.FormItem
        {...FormItemLayout}
        label={label}
        name={name}
        options={{
          initialValue: (initialValue && moment(initialValue)) || null,
          rules: [{ required: true }]
        }}
      >
        <DatePicker format={"YYYY-MM-DD"} />
      </FormSection.FormItem>
    );
  }

  renderSelectItem(
    label: string,
    name: string,
    optionList: any,
    initialValue?: string | number
  ) {
    return (
      <FormSection.FormItem
        {...FormItemLayout}
        label={label}
        name={name}
        options={{ initialValue, rules: [{ required: true }] }}
      >
        <Select>
          {optionList.map((item: any) => (
            <Select.Option value={item.value} key={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </FormSection.FormItem>
    );
  }

  renderSelectItemMultiple(
    label: string,
    name: string,
    optionList: any,
    initialValue?: string
  ) {
    return (
      <FormSection.FormItem
        {...FormItemLayout}
        label={label}
        name={name}
        options={{
          initialValue: initialValue ? initialValue.split(",") : [],
          rules: [{ required: true }]
        }}
      >
        <Select mode="multiple">
          {optionList.map((item: any) => (
            <Select.Option value={item.value} key={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </FormSection.FormItem>
    );
  }
}
export { Languages };
export default translate("book")(UpdateBookModal);
