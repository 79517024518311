import { observable, action } from "mobx";
import moment = require("moment");
import { runningWaterService } from "app/service";
import {
  DailyRunningWaterParams,
  DailyRunningWater
} from "app/models/RunningWater";
import { message } from "antd";
import i18next = require("i18next");
import * as XLSX from "xlsx";
// import { showError } from "app/utils";
// const data = require('./data.json')
const nowMonth = moment().month();
const emptyQueryCondition = {
  to_date: moment().format("YYYY-MM-DD"),
  from_date: moment()
    .month(nowMonth - 1)
    .format("YYYY-MM-DD"),
  page: 1,
  page_size: 10
};

class DailyRunningWaterStore {
  @observable runningWaterList: DailyRunningWater[] = [];
  @observable total: number = 0;
  @observable loading: boolean = false;
  @observable queryCondition: DailyRunningWaterParams = emptyQueryCondition;

  @action.bound
  async initData() {
    await this.loadBooks();
  }

  @action.bound
  async loadBooks() {
    this.loading = true;
    try {
      const runningWaterData = await runningWaterService.getRunningWater(
        this.queryCondition
      );
      let { list, total } = runningWaterData;
      this.runningWaterList = list;
      this.total = total;
    } catch (error) {
      message.error(error.message);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async reset() {
    this.queryCondition = {
      ...emptyQueryCondition,
      page_size: this.queryCondition.page_size
    };
    await this.loadBooks();
  }

  @action.bound
  async exportExl() {
    try {
      const queryCondition = {
        ...this.queryCondition,
        page_size: this.total,
        page: 1
      };
      const runningWaterList = await runningWaterService.getRunningWater({
        ...queryCondition
      });
      let { list } = runningWaterList;
      const sourceData = [
        [
          i18next.t("runningWater:name"),
          i18next.t("runningWater:bookNumbers"),
          i18next.t("runningWater:totalSalesNum(brick)")
        ]
      ];
      for (let i = 0; i < list.length; i++) {
        sourceData.push([
          list[i].date,
          list[i].bookCount,
          list[i].babelIncome.totalBrickSales || 0
        ]);
      }
      const source = XLSX.utils.aoa_to_sheet(sourceData);
      const wb = XLSX.utils.book_new();
      var ws_name = "Sheet1";
      XLSX.utils.book_append_sheet(wb, source, ws_name);
      const fileName = moment().format("YYYY/MM/DD");
      XLSX.writeFile(wb, `小说站日流水${fileName}.xlsx`);
    } catch (error) {
      throw error.message || "下载错误";
    }
  }
}
export const dailyRunningWaterStore = new DailyRunningWaterStore();
