import * as React from "react";
import { Select, Button, Input, Form, Divider } from "antd";
import { userManageStore } from "../store";
import { observer } from "mobx-react";
const { Option } = Select;
import { translate, InjectedTranslateProps } from "react-i18next";
const { TextArea } = Input;
import { CPInfoList } from "app/models/RunningWater";
import { UserInfo } from "app/models/UserManage";
interface IAddUserProps {
  cpList: CPInfoList[];
  submit: Function;
  updateUserParams: UserInfo;
  onChangeInfo: Function;
  onCancle: Function;
}

@observer
class UpdateUserPage extends React.Component<
  IAddUserProps & InjectedTranslateProps
> {
  componentDidMount() {
    userManageStore.initData();
  }

  render() {
    const t = this.props.t;
    const { updateUserParams, onChangeInfo, onCancle } = this.props;
    return (
      <Form layout="vertical">
        <Form.Item label={t("userManage:userName")}>
          <Input
            value={updateUserParams.user_name}
            placeholder={t("common:pleaseInput")}
            onChange={(eve: any) => onChangeInfo("user_name", eve.target.value)}
            disabled
          />
        </Form.Item>
        <Form.Item label={t("userManage:cp")}>
          <Select
            placeholder={t("common:pleaseSelect")}
            onChange={eve => onChangeInfo("cp", eve)}
            value={updateUserParams.cp}
          >
            {this.props.cpList.map((cp: CPInfoList) => (
              <Option key={cp.id} value={cp.id}>
                {cp.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t("userManage:remark")}>
          <TextArea
            value={updateUserParams.remark}
            placeholder={t("common:pleaseInput")}
            rows={4}
            onChange={eve => onChangeInfo("remark", eve.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button onClick={() => onCancle()}>取消</Button>
          <Divider type={"vertical"} />
          <Button type={"primary"} onClick={() => this.props.submit()}>
            确定
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default translate("dayilRunningWater")(UpdateUserPage);
