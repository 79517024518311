import * as React from "react";
import {
  Card,
  Table,
  Button,
  // Input,
  Divider,
  Form,
  DatePicker
} from "antd";
import { dailyRunningWaterStore } from "./store";
import { observer } from "mobx-react";
import moment = require("moment");
// import UpdateBookModal from "./components/update-book";
import { translate, InjectedTranslateProps } from "react-i18next";
import i18next = require("i18next");
import { twNavigate } from "../route";
// const TemplateUrl =
//   "https://img.babelchain.org/book_images/版权书目创建模板v1.xlsx";
const { RangePicker } = DatePicker;

@observer
class WaterPage extends React.Component<InjectedTranslateProps> {
  baseColumns = [
    {
      title: i18next.t("runningWater:name"),
      dataIndex: "date",
      key: "date"
    },
    {
      title: i18next.t("runningWater:bookNumbers"),
      dataIndex: "bookCount",
      key: "bookCount"
    },
    {
      title: i18next.t("runningWater:totalSalesNum(brick)"),
      dataIndex: "babelIncome.totalBrickSales",
      key: "babelIncome.totalBrickSales"
      // sorter: true
    },
    {
      title: i18next.t("common:detail"),
      dataIndex: "action",
      key: "action",
      render: (text: string, record: any) => (
        <Button
          onClick={() => {
            twNavigate("/book-sales", {
              from_date: record.date,
              to_date: record.date
            });
          }}
        >
          详情
        </Button>
      )
    }
  ];

  componentWillMount() {
    dailyRunningWaterStore.initData();
  }

  render() {
    return (
      <div>
        {this.renderSelectForm()}
        {this.renderTable()}
      </div>
    );
  }

  renderTable() {
    return (
      <Table
        dataSource={[...dailyRunningWaterStore.runningWaterList]}
        columns={this.baseColumns as any}
        rowKey="date"
        loading={dailyRunningWaterStore.loading}
        pagination={{
          total: dailyRunningWaterStore.total,
          current: dailyRunningWaterStore.queryCondition.page,
          pageSize: dailyRunningWaterStore.queryCondition.page_size,
          showSizeChanger: true,
          showQuickJumper: true
        }}
        onChange={(
          { total, current, pageSize },
          filters,
          { columnKey, order }
        ) => {
          dailyRunningWaterStore.queryCondition = {
            ...dailyRunningWaterStore.queryCondition,
            page_size: pageSize,
            page: current
          };
          // console.log(
          //   dailyRunningWaterStore.queryCondition,
          //   dailyRunningWaterStore.total
          // );
          // if (columnKey && order) {
          //   order === "descend" ? "desc" : "asc";
          //   dailyRunningWaterStore.queryCondition.order = order;
          //   dailyRunningWaterStore.initData();
          //   console.log('order', order, 'columnKey', columnKey)
          // }
          dailyRunningWaterStore.loadBooks();
        }}
      />
    );
  }

  renderSelectForm() {
    let { reset } = dailyRunningWaterStore;
    let t = this.props.t;
    const defalutValue =
      dailyRunningWaterStore.queryCondition.from_date &&
      dailyRunningWaterStore.queryCondition.to_date
        ? [
            moment(
              dailyRunningWaterStore.queryCondition.from_date,
              "YYYY-MM-DD"
            ),
            moment(dailyRunningWaterStore.queryCondition.to_date, "YYYY-MM-DD")
          ]
        : [];
    return (
      <Card>
        <Form layout="inline">
          <Form.Item label={t("saleDetail:timeScreening")}>
            <RangePicker
              value={defalutValue as any}
              placeholder={["开始时间", "结束时间"]}
              onChange={(eve: any) => {
                dailyRunningWaterStore.queryCondition = {
                  ...dailyRunningWaterStore.queryCondition,
                  from_date: eve[0].format("YYYY-MM-DD"),
                  to_date: eve[1].format("YYYY-MM-DD")
                };
              }}
            />
          </Form.Item>
          <Divider type="vertical" />
          <Form.Item>
            <Button
              type="primary"
              onClick={() => dailyRunningWaterStore.initData()}
            >
              {i18next.t("common:search")}
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              onClick={() => {
                reset();
              }}
            >
              {i18next.t("common:reset")}
            </Button>
            <Divider type="vertical" />
            <Button
              type="primary"
              onClick={() => {
                dailyRunningWaterStore.exportExl();
              }}
            >
              {i18next.t("common:export")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default translate("dayilRunningWater")(WaterPage);
