import * as queryString from "querystring";
import moment = require("moment");
import { message } from "antd";

export function omit<T extends object, K extends keyof T>(
  target: T,
  ...omitKeys: K[]
): Omit<T, K> {
  return (Object.keys(target) as K[]).reduce(
    (res: any, key) => {
      if (!omitKeys.includes(key)) {
        res[key] = target[key];
      }
      return res;
    },
    {} as Omit<T, K>
  );
}

// class DebugTracer extends Error { }
export function debug(msg: any) {
  if (process.env.DEBUG) {
    // const stack = new DebugTracer().stack!.substring(5);
    // noinspection TsLint
    // console.debug(msg, stack);
    console.debug(msg);
  }
}
export function formatTime(text?: string) {
  return text
    ? moment
        .utc(text)
        .local()
        .format("YYYY-MM-DD HH:mm:ss")
    : "none date";
}
export function getNumberPart(str?: string): number {
  if (!str) {
    return 0;
  }
  const strNumberMatch = str.match(/\d+/);
  return strNumberMatch ? parseInt(strNumberMatch[0], 10) : 0;
}

export function queryParamsFromUrl(param: string) {
  let urlParams: any = queryString.parse(
    (location && location.search.substring(1)) || ""
  );
  return urlParams && urlParams[param];
}

export function stringToHump(name: string) {
  return name.replace(/\_(\w)/g, (all, letter) => {
    return letter.toUpperCase();
  });
}

export function objectKeysToHump(obj: any) {
  let newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[`${stringToHump(key)}`] = obj[key];
    }
  }
  return newObj;
}

export function base64File(file: any) {
  return new Promise((resolve, reject) => {
    let fileRader = new FileReader();
    fileRader.readAsDataURL(file);
    fileRader.onload = (e: any) => {
      var base64 = e.target.result.split(";base64,")[1];
      resolve(base64);
    };
  });
}

export function values(obj: any) {
  if (obj !== Object(obj))
    throw new TypeError("Object.values called on a non-object");
  var val = [],
    key;
  for (key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      val.push(obj[key]);
    }
  }
  return val;
}

export function cloneObject(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function guid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

export function idsToEntitiesAction<T>(
  ids: string[],
  list: T[],
  actionItem: (item: T) => void
) {
  let results = ids
    .map(id => (list || []).find(item => (item as any).id == id))
    .filter(item => !!item);
  (results || []).map(item => {
    actionItem(item as T);
  });
}

export async function transformArrayDataToPromise<T>(
  dataArray: T[],
  dataToPromise: (item: T) => Promise<any>,
  stepSize: number
) {
  let resultPromise: Promise<any> = Promise.resolve();
  for (let i = 0; i < dataArray.length; i += stepSize) {
    let itemPromiseArray: any[] = [];
    for (
      let index = i;
      index < Math.min(i + stepSize, dataArray.length);
      index++
    ) {
      itemPromiseArray.push(dataToPromise(dataArray[index]));
    }
    // console.log(
    //   `第${Math.floor(i / stepSize)}组开始 `,
    //   itemPromiseArray.length
    // );
    await Promise.all(itemPromiseArray);
  }
  return resultPromise;
}

export function showError(error: any) {
  message.error(error.message);
  console.error(error);
}

export function formatCanonicalName(name: string) {
  let canonicalName = name
    .trim()
    .toLowerCase()
    .replace(/([^a-zA-Z])/g, " ")
    .replace(/\s+/g, "-");

  return canonicalName.substr(0, 38).replace(/[^a-zA-Z]+$/g, "");
}

export function formatPropsToObj(props: any) {
  let url: string = props.location.search;
  if (!/\?.+/.test(url)) return {};
  let result: any = {};
  url
    .slice(1)
    .split("&")
    .forEach(item => {
      let key = item.split("=")[0];
      let value: any = item.split("=")[1];
      if (/^\d+$/.test(value)) value = parseInt(value);
      result[key] = value;
    });
  return result;
}

export function showFeature(features: string[], feature: string) {
  return !!features.find(item => item == feature || item == "*");
}
export function parseBoolean(value: string | boolean): boolean {
  let isTrue = typeof value == "string" ? JSON.parse(value) : value;
  return isTrue;
}

export function isChineseStr(value: string) {
  return /^[\u4e00-\u9fa5]+$/.test(value);
}
