import * as XLSX from "xlsx";

export function readXLSXFile(file: any, option?: XLSX.Sheet2JSONOpts) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);
    fileReader.onload = (event: any) => {
      try {
        let workbook = XLSX.read(event.target.result, { type: "binary" });
        let data: any = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], option);
            resolve(data);
          }
        }
        resolve(data);
      } catch (error) {
        reject(error);
      }
    };
  });
}
