import BaseService from "./base";
import {
  GetBooksParam,
  Book,
  CreateBook,
  UploadAdultGrade
} from "app/models/Book";
import { PageList } from "app/models/PageList";

export default class BookService extends BaseService {
  public getBooks(params: GetBooksParam): Promise<PageList<Book>> {
    return this.get("/cp/books", params).then(res => res.data.data);
  }

  public createOneBook(params: CreateBook) {
    return this.post("/cp/books", params).then(res => res.data.data);
  }

  public createBooks(params: CreateBook[]) {
    return this.post("/cp/books/create-books", params).then(
      res => res.data.data
    );
  }

  public updateBooks(bookId: number, params: Book) {
    return this.put(`/cp/books/${bookId}`, params).then(res => res.data.data);
  }

  public getAllKeywords() {
    return this.get(`/cp/books/keywords`).then(res => res.data.data);
  }

  public exportBooks(params: GetBooksParam) {
    return this.get("/cp/books-export", params).then(res => res.data.data);
  }

  public updateGrade(params: UploadAdultGrade[]) {
    console.log("updateGrade", params);
    if (params.length == 0) {
      return undefined;
    }
    let updateParams: any = [];
    params.map(data => {
      let result = {
        bookName: data.bookName,
        author: data.author,
        copyrightId: data.copyrightId,
        gradeRemark: data.gradeRemark || "",
        adultGrade: data.adultGrade
      };
      updateParams.push({ ...result });
    });
    return this.post("/cp/books/update-adultGrade", updateParams).then(
      res => res.data.data
    );
  }
}
