import { observable, action } from "mobx";
import { message } from "antd";
import {
  UserManageParams,
  UserInfo,
  AddUserParams
} from "app/models/UserManage";
import { CPInfoList } from "app/models/RunningWater";
import UserManageService from "app/service/userManage";
import RunningWaterService from "app/service/runningWater";

const emptyAddUserParams = {
  user_name: "",
  password: "",
  remark: "",
  cp: ""
};

const emptyUpdateUserParams: UserInfo = {
  user_name: "",
  remark: "",
  cp: "",
  create_time: "",
  update_time: "string",
  id: "",
  cpName: ""
};

const emptyQueryConfition: UserManageParams = {
  page: 1,
  pageSize: 10
};

class UserManageStore {
  private userManageService = new UserManageService();
  private runningWaterService = new RunningWaterService();
  @observable userInfoList: UserInfo[] = [];
  @observable total: number = 0;
  @observable loading: boolean = false;
  @observable queryCondition: UserManageParams = emptyQueryConfition;
  @observable isAdd: boolean = false;
  @observable cpList: CPInfoList[] = [];
  @observable addUserParams: AddUserParams = emptyAddUserParams;
  @observable updateUserParamms: UserInfo = emptyUpdateUserParams;
  @observable isUpdate: boolean = false;

  @action.bound
  async initData() {
    await this.getCPList();
    await this.loadUserManageInfo();
  }

  @action.bound
  async loadUserManageInfo() {
    this.loading = true;
    try {
      const sourceData = await this.userManageService.getUserManageList(
        this.queryCondition,
        this.cpList
      );
      const { list, total } = sourceData;
      this.userInfoList = list;
      this.total = total;
      this.loading = true;
    } catch (error) {
      message.error(error.message);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async getCPList() {
    try {
      const data = await this.runningWaterService.getAllCPs();
      this.cpList = data.data.copyrightDetails;
    } catch (error) {
      message.error(error.message || "获取版权方出错");
    }
  }

  @action.bound
  async resetData() {
    const resetDate = {
      page: 1,
      pageSize: this.queryCondition.pageSize || 10
    };
    this.queryCondition = resetDate;
    this.loadUserManageInfo();
  }

  @action.bound
  async searchData() {
    await this.loadUserManageInfo();
  }

  @action.bound
  async createUser() {
    try {
      await this.userManageService.createUser(this.addUserParams);
      this.isAdd = false;
      await this.loadUserManageInfo();
    } catch (error) {
      throw new Error(error.message);
    }
  }

  @action.bound
  async delectPower(id: string) {
    // console.log("delect");
    try {
      await this.userManageService.delectPower(id);
      await this.loadUserManageInfo();
      message.success("删除权限成功");
    } catch (error) {
      message.error(error.message);
    }
  }

  @action.bound
  async updateUser() {
    try {
      await this.userManageService.updateUser(this.updateUserParamms);
      this.isUpdate = false;
      await this.loadUserManageInfo();
    } catch (error) {
      throw new Error(error.message);
    }
  }
}
export const userManageStore = new UserManageStore();
