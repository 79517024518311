import * as React from "react";
import { Tag, Button, Tooltip } from "antd";
import i18next = require("i18next");
import moment = require("moment");
import { Languages } from "./components/update-book";
import { bookStore } from "./store";
import { InjectedTranslateProps } from "react-i18next";
import { Book } from "app/models";
import * as styles from "./index.scss";
import { validationMap } from "./validation";

const filterLabels = [
  {
    text: "安全",
    value: 1
  },
  {
    text: "危险",
    value: 2
  },
  {
    text: "未填写",
    value: 0
  }
];

const renderAdultGradeTags = (adultGrade: string) => {
  if (!adultGrade) {
    return null;
  }
  let source = adultGrade.split(",");
  if (source.length == 1) {
    return <Tag color="blue">{source[0]}</Tag>;
  }
  const { adultGradeValue } = bookStore;
  source.shift();
  source = source.map(res => {
    let data = res.split("-");
    data[0] = adultGradeValue[data[0]];
    return data.join("-");
  });
  return source.map(res => <Tag color="blue">{res}</Tag>);
};

export const createBaseColumn = (props: InjectedTranslateProps) => [
  {
    title: i18next.t("book:bookName"),
    dataIndex: "name",
    key: "name",
    width: 140,
    fixed: "left"
  },
  {
    title: i18next.t("book:author"),
    dataIndex: "author",
    key: "author",
    width: 100
  },
  {
    title: i18next.t("book:textLength"),
    dataIndex: "textLength",
    key: "textLength",
    width: 90
  },
  {
    title: i18next.t("book:targetAudience"),
    dataIndex: "targetAudience",
    key: "targetAudience",
    width: 80,
    render: (targetAudience: number) => {
      if (targetAudience === 1) {
        return "男频";
      }
      if (targetAudience === 2) {
        return "女频";
      }
      return "";
    }
  },
  {
    title: i18next.t("book:zhGenres"),
    dataIndex: "zhGenres",
    key: "zhGenres",
    width: 100,
    render: (zhGenres: string) => {
      const genres = (zhGenres && zhGenres.split(",")) || [];
      if (genres.length) {
        return genres.map(item => (
          <Tag color="blue" key={item}>
            {item}
          </Tag>
        ));
      }
      return "";
    }
  },
  {
    title: i18next.t("book:isCompleted"),
    dataIndex: "isCompleted",
    key: "isCompleted",
    width: 100,
    render: (isCompleted: number) => {
      if (isCompleted === 1) {
        return props.t("book:completed");
      }
      if (isCompleted === 0) {
        return props.t("book:serialize");
      }
      return "";
    }
  },
  {
    title: i18next.t("book:copyrightId"),
    dataIndex: "copyrightId",
    key: "copyrightId",
    width: 100,
    render: (copyrightId: number, row: any) => {
      if (row.copyrightName) {
        return row.copyrightName;
      }
      return bookStore.getCopyrightNameById(copyrightId);
    }
  },
  {
    title: i18next.t("book:grade"),
    dataIndex: "grade",
    key: "grade",
    width: 100,
    sorter: true
  },

  {
    title: i18next.t("book:gainsharingRate"),
    dataIndex: "gainsharingRate",
    key: "gainsharingRate",
    width: 110,
    sorter: true
  },
  {
    title: i18next.t("book:guaranteedAmount"),
    dataIndex: "guaranteedAmount",
    key: "guaranteedAmount",
    width: 140,
    sorter: true
  },
  {
    title: i18next.t("book:authorizationChannel"),
    dataIndex: "authorizationChannel",
    key: "authorizationChannel",
    width: 110,
    sorter: true
  },
  {
    title: i18next.t("book:authorizationLanguage"),
    dataIndex: "authorizationLanguage",
    key: "authorizationLanguage",
    width: 110,
    sorter: true,
    render: (authorizationLanguage: string) => {
      if (!authorizationLanguage) return "";
      var isEnglish = /^[a-zA-Z]+$/.test(authorizationLanguage.slice(0, 1));
      if (!isEnglish) return authorizationLanguage;

      const targetAuthorizationLanguage: string[] = [];
      const authorizationLanguageArray = authorizationLanguage.split(",");
      authorizationLanguageArray.forEach(language => {
        const { label } = Languages.find(({ value }) => value === language) || {
          label: ""
        };
        targetAuthorizationLanguage.push(label);
      });
      return targetAuthorizationLanguage.join(",");
    }
  },
  {
    title: i18next.t("book:isMutualAuthorization"),
    width: 80,
    dataIndex: "isMutualAuthorization",
    key: "isMutualAuthorization",
    render: (isMutualAuthorization: number) => {
      if (isMutualAuthorization === 1) {
        return props.t("yes");
      }
      if (isMutualAuthorization === 0) {
        return props.t("no");
      }
      return "";
    }
  },
  {
    title: i18next.t("book:authorizationStartAt"),
    dataIndex: "authorizationStartAt",
    key: "authorizationStartAt",
    width: 140,
    sorter: true,
    render: (authorizationStartAt: number) => {
      return moment(authorizationStartAt).format("YYYY-MM-DD");
    }
  },
  {
    title: i18next.t("book:authorizationEndAt"),
    dataIndex: "authorizationEndAt",
    key: "authorizationEndAt",
    width: 140,
    sorter: true,
    render: (authorizationEndAt: number) => {
      return moment(authorizationEndAt).format("YYYY-MM-DD");
    }
  },
  {
    title: i18next.t("book:sensitivityRating"),
    dataIndex: "sensitivityRating",
    key: "sensitivityRating",
    width: 100
  },
  {
    title: i18next.t("book:adultGrade"),
    dataIndex: "adultGrade",
    key: "adultGrade",
    width: 110,
    render: (test: string) => renderAdultGradeTags(test),
    filters: filterLabels,
    filterMultiple: false
  }
];

export const createBookColumn = (props: InjectedTranslateProps) => {
  let columns: any[] = createBaseColumn(props);
  columns = columns.concat([
    {
      title: i18next.t("book:remark"),
      dataIndex: "remark",
      key: "remark",
      width: 100,
      render: (remark: string) => {
        if (remark) {
          return <Tooltip title={remark}>{remark.slice(0, 3) + "..."}</Tooltip>;
        }
        return "";
      }
    },
    {
      title: "语言(zh/eng/spa)",
      dataIndex: "language",
      key: "language"
    },
    {
      title: "isbn",
      dataIndex: "isbn",
      key: "isbn"
    }
  ]);
  return columns
    .filter((item: any) => item.key !== "adultGrade")
    .map((item: any) => {
      return {
        title: item.title,
        dataIndex: item.dataIndex,
        key: item.key,
        width: item.width || "auto",
        render: (text: any, row: any) => {
          const empty = <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />;
          const validationFn =
            (validationMap as any)[item.dataIndex] || (() => true);
          const renderFn = () => {
            if (
              item.dataIndex === "authorizationStartAt" ||
              item.dataIndex === "authorizationEndAt"
            ) {
              return text || empty;
            }
            if (item.render && item.render(text, row)) {
              return item.render(text, row);
            }
            if (item.render && !item.render(text, row)) {
              return empty;
            }
            return text || empty;
          };
          if (!validationFn(text, row))
            bookStore.createBooksModal.valid = false;
          return (
            <div style={{ position: "relative" }}>
              <div className={validationFn(text, row) ? "" : styles.warn} />
              {renderFn()}
            </div>
          );
        }
      };
    });
};

export const createGradeColumn = () => [
  {
    title: i18next.t("book:bookName"),
    dataIndex: "bookName",
    key: "bookName"
  },
  {
    title: i18next.t("book:author"),
    dataIndex: "author",
    key: "author"
  },
  {
    title: i18next.t("book:copyrightId"),
    dataIndex: "copyrightName",
    key: "copyrightName"
  },
  {
    title: i18next.t("book:safe"),
    dataIndex: "safe",
    key: "safe"
  },
  {
    title: i18next.t("book:sexy"),
    dataIndex: "sexy",
    key: "sexy"
  },
  {
    title: i18next.t("book:badHabit"),
    dataIndex: "badHabit",
    key: "badHabit"
  },
  {
    title: i18next.t("book:violenceAndAbuse"),
    dataIndex: "violenceAndAbuse",
    key: "violenceAndAbuse"
  },
  {
    title: i18next.t("book:zh"),
    dataIndex: "zh",
    key: "zh"
  },
  {
    title: i18next.t("book:zq"),
    dataIndex: "zq",
    key: "zq"
  },
  {
    title: i18next.t("book:qt"),
    dataIndex: "qt",
    key: "qt"
  },
  {
    title: i18next.t("book:gradeRemark"),
    dataIndex: "gradeRemark",
    key: "gradeRemark"
  }
];

export const createColumn = (props: InjectedTranslateProps) => {
  let columns: any[] = createBaseColumn(props);
  columns = columns.concat([
    {
      title: i18next.t("book:leftMonth"),
      dataIndex: "authorizationEndAt",
      key: "lastMonth",
      width: 120,
      render: (authorizationEndAt: number) => {
        let month = moment(authorizationEndAt).diff(moment.now(), "month") || 0;
        if (month < 3) {
          return <Tag color="red">{month}</Tag>;
        }
        return <Tag color="blue">{month}</Tag>;
      }
    },
    {
      title: i18next.t("book:remark"),
      dataIndex: "remark",
      key: "remark",
      width: 100,
      render: (remark: string) => {
        if (remark) {
          return <Tooltip title={remark}>{remark.slice(0, 3) + "..."}</Tooltip>;
        }
        return "";
      }
    },
    {
      title: i18next.t("book:option"),
      key: "option",
      fixed: "right",
      render: (row: Book) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              bookStore.updateModal = {
                visible: true,
                book: row,
                loading: false
              };
              const adultData = row.adultGrade;
              if (!!adultData) {
                let data = adultData.split(",");
                bookStore.isSafe = data[0] == "安全" ? true : false;
                data.shift();
                const adult: any = {};
                data.map(res => {
                  let data = res.split("-");
                  let content: any = {};
                  data[1].split("").map((status, index) => {
                    let res: any = {};
                    content[index + 1] = status;
                    return res;
                  });
                  adult[data[0]] = content;
                });
                bookStore.adultGrade = adult;
              } else {
                bookStore.isSafe = true;
                bookStore.adultGrade = {};
              }
            }}
          >
            {props.t("edit")}
          </Button>
        );
      }
    }
  ]);
  (columns[6] as any).filters = bookStore.copyrights.map(item => {
    return {
      text: item.name,
      value: item.id
    };
  });
  (columns[4] as any).filters = bookStore.keywords.map(item => {
    return {
      text: item,
      value: item
    };
  });
  return columns;
};

export default {
  createBaseColumn,
  createBookColumn,
  createGradeColumn,
  createColumn
};
