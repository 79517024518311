import * as React from "react";
import { FormSection } from "app/components/FormSection";
import { systemConfigStore } from "./store";
import {
  Skeleton,
  message,
  Input,
  Form,
  Button,
  Divider,
  PageHeader
} from "antd";
import { SystemConfig } from "app/models/SystemConfig";
import { showError } from "app/utils";
import { translate } from "react-i18next";
import { observer } from "mobx-react";

const pageStore = systemConfigStore;
@observer
class SystemConfigPage extends React.Component<{ t: any }> {
  componentWillMount() {
    pageStore.initData();
  }
  render() {
    if (pageStore.loadingList) return <Skeleton />;
    let t = this.props.t;
    const entity: Partial<SystemConfig> = pageStore.entity || {};
    return (
      <div>
        <PageHeader title={t("title")} />
        <FormSection.Form
          onSubmit={async values => {
            try {
              await pageStore.save(values);
              message.success(t("saveSuccess"));
            } catch (err) {
              showError(err);
            }
          }}
        >
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("popularizeCost")}
            name="popularizeCost"
            options={{ initialValue: entity.popularizeCost }}
          >
            <Input />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("invoiceProject")}
            name="invoiceProject"
            options={{ initialValue: entity.invoiceProject }}
          >
            <Input />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("invoiceTitle")}
            name="invoiceTitle"
            options={{ initialValue: entity.invoiceTitle }}
          >
            <Input />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("taxpayerId")}
            name="taxpayerId"
            options={{ initialValue: entity.taxpayerId }}
          >
            <Input />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("bankAccount")}
            name="bankAccount"
            options={{ initialValue: entity.bankAccount }}
          >
            <Input />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("bank")}
            name="bank"
            options={{ initialValue: entity.bank }}
          >
            <Input />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("registerAddress")}
            name="registerAddress"
            options={{ initialValue: entity.registerAddress }}
          >
            <Input.TextArea />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("contact")}
            name="contact"
            options={{ initialValue: entity.contact }}
          >
            <Input />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("email")}
            name="email"
            options={{ initialValue: entity.email }}
          >
            <Input />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("telephone")}
            name="telephone"
            options={{ initialValue: entity.telephone }}
          >
            <Input />
          </FormSection.FormItem>
          <FormSection.FormItem
            {...FormSection.formItemLayout}
            label={t("address")}
            name="address"
            options={{ initialValue: entity.address }}
          >
            <Input.TextArea />
          </FormSection.FormItem>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              loading={pageStore.loadingSave}
              htmlType="submit"
            >
              {t("common:submit")}
            </Button>
            <Divider type="vertical" />
            <FormSection.ResetButton type="primary">
              {t("common:reset")}
            </FormSection.ResetButton>
          </Form.Item>
        </FormSection.Form>
      </div>
    );
  }
}

export default translate("systemConfig")(SystemConfigPage);
