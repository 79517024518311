import * as React from "react";
import * as styles from "./styles.scss";
import { loginStore } from "app/pages/login/store";
import { Form, Icon, Input, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";

class LoginForm extends React.Component<FormComponentProps> {
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        loginStore.username = values.username;
        loginStore.password = values.password;
        loginStore.login();
      }
    });
  };

  render() {
    return (
      <div className={styles.contain}>
        <div className={styles.cover}>
          <div className={styles.wrapper}>
            <img
              src={require("./assets/logo@2x.png")}
              style={{ width: 160, height: 32 }}
            />
            <span className={styles.title}>{"版权管理后台"}</span>
            {this.renderForm()}
          </div>
        </div>
        <div className={styles["platform"]}>
          <div>{"推文SaaS出海平台"}</div>
          <div>{"Copyright 2020 - 2021 Tuiwen. All Rights Reserved"}</div>
        </div>
      </div>
    );
  }
  renderForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className={styles["login-form"]}>
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
              className={styles["form-input"]}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
              className={styles["form-input"]}
            />
          )}
        </Form.Item>
        {loginStore.showErr && (
          <span className={styles["text"]}>{"账号或密码错误，请重新输入"}</span>
        )}
        <Form.Item>
          <Button
            loading={loginStore.loading}
            type="primary"
            htmlType="submit"
            className={styles["login-form-button"]}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export const LoginPage = Form.create()(LoginForm);
