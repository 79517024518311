import { SystemConfig } from "app/models/SystemConfig";
import { systemConfigService } from "app/service";
import { showError } from "app/utils";
import { observable } from "mobx";

class SystemConfigStore {
  @observable loadingList: boolean = false;
  @observable loadingSave: boolean = false;
  @observable entity: Partial<SystemConfig> = {};
  async initData() {
    this.loadingList = true;
    try {
      this.entity = await systemConfigService.getOne();
    } catch (err) {
      showError(err);
    }
    this.loadingList = false;
  }
  async save(params: Partial<SystemConfig>) {
    this.loadingSave = true;
    try {
      await systemConfigService.update(params);
      this.loadingSave = false;
    } catch (err) {
      this.loadingSave = false;
      throw err;
    }
  }
}
export const systemConfigStore = new SystemConfigStore();
